import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Edit } from "react-feather";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Media,
  Table,
  CardBody,
} from "reactstrap";
import { getPlantDetails } from "../../../../api";
import Chart from "react-apexcharts";
import { apexAreaChart } from "../string/StringChartData";
import { Link } from "react-router-dom";
import dummyPlantPerfomance from "../../../../api/mock-data/plant-data.json";
import { primary, secondary } from "../string/StringChartData";
import Breadcrumbs from "../../../../layout/breadcrumb";

const PlantDetails = (props) => {
  const { id } = useParams();
  const [plantDetails, setPlantDetails] = useState([]);
  const [blocksList, setBlocksList] = useState([]);
  const [plantPerformanceData, setPlantPerformanceData] = useState([]);

  const loadPlantPerformanceData = async (plantId) => {
    const data = dummyPlantPerfomance?.[plantId] || [];
    setPlantPerformanceData(data);
  };

  const getData = async () => {
    const { data } = await getPlantDetails(id);
    setPlantDetails(data.data);
    setBlocksList(data.data.blocks);
    loadPlantPerformanceData(data.data?._id);
  };
  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);
  return (
    <Fragment>
      <Breadcrumbs title="Plant Details" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="12">
            <Card>
              <CardHeader>
                <h5>{"Plant Performance"} </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      yaxis: {
                        title: {
                          text: "Power (MWh)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        title: {
                          text: "Time",
                        },
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          plantPerformanceData?.[0]?.date
                        ).getTime(),
                      },
                    }}
                    series={[
                      {
                        name: "Plant Performance",
                        data: plantPerformanceData.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media-body align-self-center">
                        <h1 className="mt-0 user-name">{"Plant Details"}</h1>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start">
                        <h6>Plant ID</h6>
                        <span>{plantDetails?._id}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Plant Name</h6>
                        <span>{plantDetails?.name}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Raw Address</h6>
                        <span>{plantDetails?.address?.raw_address}</span>
                      </div>
                    </Col>
                  </Row>

                  {/* <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-end pe-3">
                        <label className="m-0">
                          <a href="#javascript">
                            <i className="fa fa-heart"></i>
                          </a>
                            {Like}
                        </label>
                        <span className="ms-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ms-2">
                        <label className="m-0">
                          <a href="#javascript">
                            <i className="fa fa-comment"></i>
                          </a>
                            {Comment}
                        </label>
                        <span className="ms-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="12">
                      <div className="media-body align-self-center">
                        <h5 className="mt-0 user-name">{"Blocks list"}</h5>
                      </div>
                    </Col>
                    {/* modules in the given string */}
                    <div className="table-responsive">
                      <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">{"#"}</th>
                            <th scope="col">{"Block ID"}</th>
                            <th scope="col">{"Block Name"}</th>
                            <th scope="col">{"Actions"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blocksList?.map((block, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <Link to={`/block/${block?._id}`}>
                                    {block?._id}
                                  </Link>
                                </td>
                                <td>{block?.name}</td>
                                <td>
                                  <div>
                                    <Edit />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <hr />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default PlantDetails;
