import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { toast } from "react-toastify";

export const BASE_API_URL =
  "https://projects.emertech.io/poc/adani/module-trace/api";

// SOLAR APP

const URL = "https://projects.emertech.io/poc/adani/module-trace/api";

//Module trace data
export const getModuleData = (moduleId) => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/module/${moduleId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//get Plant data
export const getPlantListData = () => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/plant`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//get plant details
export const getPlantDetails = (plantId) => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/plant/${plantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//block list data
export const getBlockListData = () => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/block`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBlockDetails = (blockId) => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/block/${blockId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// inverters list data
export const getInverterListData = () => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/inverter`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//get inverter Details
export const getInverterDetails = (inverterId) => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/inverter/${inverterId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//strings list data
export const getStringListData = async () => {
  let token = localStorage.getItem("token");
  try {
    const stringList = await axios.get(`${URL}/string`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = stringList?.data?.data;
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//get single string's data
export const getStringData = async (stringId) => {
  let token = localStorage.getItem("token");
  try {
    const stringData = await axios.get(`${URL}/string/${stringId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = stringData?.data?.data || {};
    return data;
  } catch (err) {
    console.log(err);
    return {};
  }
};

//claims list
export const getAllClaimsData = async () => {
  let token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${BASE_API_URL}/claim`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    toast.error("something went wrong!");
  }
};

// Warranty Claim Details
export const getWarrantyClaimDetails = async (claimID) => {
  let token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${BASE_API_URL}/claim/${claimID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

//get string performance data
export const getStringPerformanceData = async (stringId) => {
  try {
    const token = localStorage.getItem("token");
    const url = `${URL}/string/performance?string_id=${stringId}`;
    const performanceData = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = performanceData?.data?.data || [];

    data.sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)));

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//get alert data
export const getAlerts = async (count = 10, limitDays = 3) => {
  const token = localStorage.getItem("token");
  const user = jwtDecode(token);
  try {
    const url = `${URL}/alert`;

    let todayDate = new Date();
    let limitDate = todayDate.setDate(todayDate.getDate() - limitDays);
    let time = JSON.parse(localStorage.getItem("adani-solar-notifications"));
    let notificationTime = new Date(time);

    const payload = {
      user_id: user?.id,
      limit: count,
      createdAt: { $gt: limitDate },
    };

    const alerts = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = alerts?.data?.data;
    const updatedData = data.map((notification) => {
      return moment(notification.createdAt).isBefore(notificationTime)
        ? { ...notification, isRead: true }
        : { ...notification, isRead: false };
    });

    return updatedData;
  } catch (error) {
    console.log(error);
    return [];
  }
};

//get modules list
export const getModulesList = () => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/module`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getModulesDetails = (moduleId) => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/module/${moduleId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// post tests module

export const uploadTests = async (values) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.post(`${BASE_API_URL}/test`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Test added successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while adding test");
  }
};

//post report

export const uploadReports = async (values) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.post(`${BASE_API_URL}/report`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Report added successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while adding report");
  }
};

// Edit Module

export const editModule = async (values, id) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.patch(`${BASE_API_URL}/module/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Modules remapped successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while remapping modules");
  }
};

// Edit Plant

export const editPlant = async (values, id) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.patch(`${BASE_API_URL}/plant/${id}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Plant edited successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while editing plant");
  }
};

// Delete Module

export const DeleteModule = async (id) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.delete(`${BASE_API_URL}/module/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Modules deleted successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while deleting module");
  }
};

// Delete Plant

export const DeletePlant = async (id) => {
  let token = localStorage.getItem("token");
  try {
    let response = await axios.delete(`${BASE_API_URL}/plant/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
    if (response) {
      toast.success("Plant deleted successfully");
    }
    return response;
  } catch (error) {
    toast.error("Error while deleting plant");
  }
};

export const getReports = () => {
  let token = localStorage.getItem("token");
  return axios.get(`${URL}/report`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchProjectApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/project.json`);
};

//Solar Apis

export const registerModules = async (values) => {
  // const finalObj = {
  //   email: values.email,
  //   password: values.password,
  // };
  // let endpoint = `${process.env.API_URL}/registration`;
  let endpoint = `${BASE_API_URL}/registration`;
  return toast.promise(
    new Promise(async (resolve, reject) => {
      let response = await axios.post(endpoint, values);
      try {
        if (response) {
          // const user = jwt_decode(response.data.token);
          resolve(response);
          return response;
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    }),
    {
      pending: "Registering...",
      success: "Registration Successfully",
      error: "Error registering",
    }
  );
};

export const signUpAuth = async (name, email, password) => {
  let finalObj = {
    name: name,
    email: email,
    password: password,
  };

  // let endpoint = `${process.env.API_URL}/registration`;
  let endpoint = `${BASE_API_URL}/user`;
  return toast.promise(
    new Promise(async (resolve, reject) => {
      let response = await axios.post(endpoint, finalObj);
      try {
        if (response) {
          console.log(response);
          resolve(response);
          window.location.href = `${process.env.PUBLIC_URL}/login`;
          return response;
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    }),
    {
      pending: "Signing Up...",
      success: "Sign Up Successfully",
      error: "Error signing Up",
    }
  );
};

// export const loginAuth = async (email, password) => {
//   let finalObj = {
//     email: email,
//     password: password,
//   };

//   // let endpoint = `${process.env.API_URL}/registration`;
//   let endpoint = `${BASE_API_URL}/user/login`;
//   return toast.promise(
//     new Promise(async (resolve, reject) => {
//       try {
//         let response = await axios.post(endpoint, finalObj);
//         if (response) {
//           localStorage.setItem("token", response?.data?.token);
//           resolve(response?.data?.token);
//           return response?.data?.token;
//         }
//       } catch (error) {
//         reject(error);
//         console.log(error, "In Error");
//       }
//     }),
//     {
//       pending: "Signing In...",
//       success: "Sign In Successfully",
//       error: "Error signing In",
//     }
//   );
// };

export const registerModule = async (serialNumber) => {
  let token = localStorage.getItem("token");
  let finalObj = {
    serialNumber: serialNumber,
  };
  try {
    let response = await axios.post(`${BASE_API_URL}/registration`, finalObj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "response ");
  } catch (error) {
    toast.error(`${error?.message}`);
  }
};

export const loginAuth = async (email, password) => {
  let finalObj = {
    email: email,
    password: password,
  };

  // let endpoint = `${process.env.API_URL}/registration`;
  try {
    let endpoint = `${BASE_API_URL}/user/login`;
    let response = await axios.post(endpoint, finalObj);
    if (response?.data?.token) {
      // const user = jwt_decode(response.data.token);
      localStorage.setItem("token", response?.data?.token);
      console.log(response.data.token);
      toast.success("Sign in successfully");
      return response?.data?.token;
    }
  } catch (error) {
    toast.error(`${error?.message}`);
  }
};

export const logout = () => {
  localStorage.removeItem("token");
};

export const getAdminDasboardDetails = async (filters = {}) => {
  try {
    let token = localStorage.getItem("token");

    console.log(filters);
    let endpoint = `${BASE_API_URL}/dashboard/admin`;
    let response = await axios.get(endpoint, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getClientDasboardDetails = async (filters = {}) => {
  try {
    let token = localStorage.getItem("token");

    console.log(filters);
    let endpoint = `${BASE_API_URL}/dashboard/clinet`;
    let response = await axios.get(endpoint, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDashboardMetrics = async (filters = {}) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/dashboard`;
    let response = await axios.get(endpoint, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getDashboardStringData = async (type = "best", filters = {}) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/dashboard/string/${type}`;
    let response = await axios.get(endpoint, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data || {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getClaimMetrics = async (filters = {}) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/dashboard/claim`;
    let response = await axios.get(endpoint, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};



export const createPlant = async (payload) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/plant`;
    let response = await axios.post(endpoint, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.dat || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createBlock = async (payload) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/block`;
    let response = await axios.post(endpoint, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createInverter = async (payload) => {
  try {
    let token = localStorage.getItem("token");

    let endpoint = `${BASE_API_URL}/inverter`;
    let response = await axios.post(endpoint, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
