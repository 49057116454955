import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
// import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table } from "reactstrap";
import { getModulesList } from "../../../../api";
import DeleteModuleModal from "./deleteModal";
import EditModuleModal from "./EditModuleModal";
// import {
//   BasicTable,
//   InverseTable,
//   InverseTablePrimaryBackground,
//   HoverableRows,
//   ContextualClasses,
//   TextBackgroundUtilities,
//   TableHeadOptions,
//   StripedRow,
//   StripedRowInverseTable,
//   Caption,
//   ResponsiveTables,
//   BreckpointSpecific,
// } from "../../../../../constant";

const ModuleTable = ({ refetch, setRefetch }) => {
  const [module, setModule] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  const deletetoggle = () => setShowDeleteModal(!showDeleteModal);

  const getData = async () => {
    const { data } = await getModulesList();
    setModule(data?.data);
  };

  console.log(module, "module")

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  return (
    <Fragment>
      {/* <Breadcrumb parent="Tables" title="Basic Tables" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Module Serial Number"}</th>
                      <th scope="col">{"String ID"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {module?.map((module, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>

                          <td><Link to={`/Modules/${module?._id}`}>{module?.serial_number}</Link></td>
                          <td><Link to={`/string/${module?.string_id}`}>{module?.string_id}</Link></td>
                          <td>
                            <div>
                              <Edit
                                color="#7366ff"
                                onClick={() => {
                                  setShowModal(!showModal);
                                  setSelectedModuleId(module?._id);
                                }}
                                style={{ cursor: "pointer" }}
                              />{" "}
                              {/* &nbsp;
                              <Trash2
                                onClick={() => {
                                  setShowDeleteModal(!showDeleteModal);
                                  setSelectedModuleId(module?._id);
                                }}
                                style={{ cursor: "pointer" }}
                                color="crimson"
                              /> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <EditModuleModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  Modaltoggle={Modaltoggle}
                  setRefetch={setRefetch}
                  moduleId={selectedModuleId}
                />
                <DeleteModuleModal
                  showModal={showDeleteModal}
                  setShowModal={setShowDeleteModal}
                  Modaltoggle={deletetoggle}
                  setRefetch={setRefetch}filter
                  moduleId={selectedModuleId}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ModuleTable;
