import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Edit } from "react-feather";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Media,
  Table,
  CardBody,
  Button,
} from "reactstrap";
import {
  BASE_API_URL,
  getStringData,
  getStringPerformanceData,
} from "../../../../api";
import moment from "moment";

import Chart from "react-apexcharts";
import { primary, secondary } from "./StringChartData";
import axios from "axios";
import { dateFormat } from "../modules/ModulesDetails";
import AddSnapshotModal from "./AddSnapshotModal";
import Breadcrumbs from "../../../../layout/breadcrumb";

const StringDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);
  const [refetch, setRefetch] = useState(false);

  const { id } = useParams();

  const [string, setString] = useState({});
  const [stringPerformanceData, setStringPerformanceData] = useState([]);

  const [moduleList, setModuleList] = useState([]);

  const getData = async () => {
    const data = await getStringData(id);
    const performanceData = await getStringPerformanceData(id);
    setString(data);
    setStringPerformanceData(performanceData);
    setModuleList(data.modules);
  };

  // const getStringPerformance = async() => {
  //   const token = localStorage.getItem("token")
  //   const {data} = await axios.get(`${BASE_API_URL}/string/performance?string_id=${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //   console.log(data, "peroformance")

  // }

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  // const labelData = stringPerformanceData.map((item) =>
  //   moment(item?.timestamp).format('MMM/YY')
  // );

  return (
    <Fragment>
      <Breadcrumbs title="String Details" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="12">
            <Card>
              <CardHeader>
                <h5>{"String Performance"} </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [primary, secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      grid: {
                        show: false,
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      yaxis: {
                        title: {
                          text: "Power (W)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          stringPerformanceData?.[0]?.timestamp
                        ).getTime(),
                        title: {
                          text: "Time",
                        },
                      },
                    }}
                    series={[
                      {
                        name: "String Performance",
                        data: stringPerformanceData.map((item) => ({
                          y: item?.converted_power?.toFixed(1),
                          x: new Date(item?.timestamp).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                      {
                        name: "Base Line Performance",
                        data: stringPerformanceData.map((item) => ({
                          y: item?.baseline_power?.toFixed(1) || 5000,
                          x: new Date(item?.timestamp).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                      {
                        name: "Max Power",
                        data: stringPerformanceData.map((item) => ({
                          y: string?.max_power?.toFixed(1) || 7000,
                          x: new Date(item?.timestamp).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media-body align-self-center">
                        <h1 className="mt-0 user-name">{"String Details"}</h1>
                      </div>
                    </Col>
                    {/* <Col sm="4" className="align-self-center">
                        <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                      </Col> */}
                  </Row>

                  <hr />

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start">
                        <h6>String ID</h6>
                        <span>{string?.id}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>String Name</h6>
                        <span>{string?.name}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Inverter ID</h6>
                        <span>{string?.inverter_id}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Raw Address</h6>
                        <span>{string?.address?.raw_address}</span>
                      </div>
                    </Col>
                  </Row>
                  {/* 
                    <div className="img-container">
                      <div id="aniimated-thumbnials">
                        <a href="#javascript">
                          <Media className="img-fluid rounded" />
                        </a>
                      </div>
                    </div> */}
                  {/* <div className="like-comment mt-4">
                      <ul className="list-inline"> 
                        <li className="list-inline-item border-end pe-3">
                          <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                        </li>
                        <li className="list-inline-item ms-2">
                          <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                        </li>
                      </ul>
                    </div> */}
                </div>
              </Card>
            </Col>
            <Row>
              <Col sm="12">
                <Card>
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="8" className="media-body">
                          <h5 className="mt-0 user-name">
                            {"String Snapshots"}
                          </h5>
                        </Col>
                        <Col sm="4">
                          <Button color="primary" onClick={Modaltoggle}>
                            Add Snapshots
                          </Button>
                        </Col>
                        <AddSnapshotModal
                          showModal={showModal}
                          Modaltoggle={Modaltoggle}
                          stringID={id}
                          setShowModal={setShowModal}
                          setRefetch={setRefetch}
                        />
                      </Row>
                      {/* modules in the given string */}
                      <div className="table-responsive">
                        <Table borderless>
                          <thead>
                            <tr>
                              <th scope="col">{"#"}</th>
                              <th scope="col">{"Timestamp"}</th>
                              <th scope="col">{"Temp. Corrected Power (W)"}</th>
                              <th scope="col">
                                {"Average Irradiation (W/m^2)"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {stringPerformanceData?.map((data, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{dateFormat(data?.timestamp)}</td>
                                  <td>{data?.converted_power.toFixed(1)}</td>
                                  <td>{data?.average_irradiance.toFixed(1)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                    <hr />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Card>
                  <div className="profile-img-style">
                    <Row>
                      <Col sm="12">
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Modules list"}</h5>
                        </div>
                      </Col>
                      {/* modules in the given string */}
                      <div className="table-responsive">
                        <Table borderless>
                          <thead>
                            <tr>
                              <th scope="col">{"#"}</th>
                              <th scope="col">{"Module ID"}</th>
                              <th scope="col">{"Module Serial Number"}</th>
                              <th scope="col">{"Efficiency (Watts)"}</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {moduleList?.map((module, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{module?.id}</td>
                                  <td>{module?.serial_number}</td>
                                  <td>{module?.efficiency?.toFixed(3)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                    <hr />
                  </div>
                </Card>
              </Col>
            </Row>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default StringDetails;
