import React, { Fragment, useState, useEffect, useRef } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { firebase_app } from "../../../data/config";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
} from "reactstrap";
import defaultuser from "../../../assets/images/user/user.png";
import search from "../../../assets/images/search-not-found.png";
// import {
//   createUser,
//   deletedUser,
//   editUser,
// } from "../../../services/contact.service";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
// import ReactToPrint from "react-to-print";
// import PrintPreview from "./printpreview";
import { classes } from "../../../data/layouts";
import { Link } from "react-router-dom";
import {
  MarkJecno,
  MARKJENCOEMAIL,
  MARKJENCO,
  NewContacts,
  AddContacts,
  Views,
  Name,
  Age,
  Mobile,
  MobileNo,
  EmailAddress,
  Gender,
  Male,
  Female,
  FollowUp,
  Orgenization,
  Favourites,
  History,
  ContactHistory,
  AddCategory,
  Ideas,
  SarahLoren,
  AndewJon,
  JasonBorne,
  Business,
  Holidays,
  Important,
  Personal,
  Edit,
  Delete,
  Print,
  General,
  Save,
  Cancel,
  NoDataFound,
  PrintViews,
} from "../../../constant";
import { Users } from "react-feather";
import PlantTable from "./solar-monitoring/plant/plant-table";

const SolarMonitoring = (props) => {
  const [addurl, setAddurl] = useState(defaultuser);
  const [editurl, setEditurl] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [dynamictab, setDynamicTab] = useState("0");
  const [orgactiveTab, setorgActiveTab] = useState("1");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // initialise the hook
  const [users, setUsers] = useState([]);
  const [editdata, setEditData] = useState({});
  const [editing, setEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const db = firebase_app.firestore();
  const [printmodal, setprintModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const toggle = () => setModal(!modal);
  const categoryToggle = () => setCategoryModal(!categoryModal);
  const printModalToggle = () => setprintModal(!printmodal);
  const componentRef = useRef();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    const unsubscribe = db.collection("contactApp").onSnapshot((snapshot) => {
      const getUser = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(getUser);
      setSelectedUser(getUser[0]);
    });
    return () => unsubscribe();
  }, [db]);

  // const AddContact = (data) => {
  //   if (data !== "") {
  //     createUser(data, addurl);
  //     setModal(false);
  //   } else {
  //     errors.showMessages();
  //   }
  // };

  // const UpdateContact = (data) => {
  //   if (data !== "") {
  //     editUser(data, editurl, editdata.id);
  //     setEditing(false);
  //   } else {
  //     errors.showMessages();
  //   }
  // };

  // const EditUSers = (usersData) => {
  //   setEditing(true);
  //   setEditData(usersData);
  //   setEditurl(usersData.avatar);
  // };

  // const HandleAddUrl = (event) => {
  //   if (event.target.files.length === 0) return;
  //   //Image upload validation
  //   var mimeType = event.target.files[0].type;

  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }
  //   // Image upload
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     setAddurl(reader.result);
  //   };
  // };

  // const HandleEditUrl = (event) => {
  //   if (event.target.files.length === 0) return;
  //   //Image upload validation
  //   var mimeType = event.target.files[0].type;

  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }
  //   // Image upload
  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     setEditurl(reader.result);
  //   };
  // };

  // const deleteUser = (userId) => {
  //   SweetAlert.fire({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this imaginary file!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Ok",
  //     cancelButtonText: "cancel",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.value) {
  //       deletedUser(userId);
  //       SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
  //     } else {
  //       SweetAlert.fire("Your imaginary file is safe!");
  //     }
  //   });
  // };

  const history = () => {
    document.querySelector(".history").classList.add("show");
  };

  const closehistory = () => {
    document.querySelector(".history").classList.remove("show");
  };

  // const ContactDetails = (user) => {
  //   setSelectedUser({
  //     id: user.id,
  //     name: user.name,
  //     surname: user.surname,
  //     avatar: user.avatar,
  //     age: user.age,
  //     mobile: user.mobile,
  //   });
  // };

  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Solar Monitoring" />
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-4">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu contact-options" role="tablist">
                        {/* <NavItem>
                          <button
                            className="badge-light-primary btn-mail"
                            onClick={toggle}
                          >
                            <Users className="me-2" />
                            {NewContacts}
                          </button>
                          <Modal
                            className="modal-bookmark"
                            isOpen={modal}
                            toggle={toggle}
                            size="lg"
                          >
                            <ModalHeader toggle={toggle}>
                              {AddContacts}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                                className="form-bookmark needs-validation"
                                onSubmit={handleSubmit(AddContact)}
                              >
                                <Row>
                                  <div className="contact-profile">
                                    <img
                                      className="rounded-circle img-100"
                                      src={addurl}
                                      alt=""
                                    />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input
                                          className="upload"
                                          type="file"
                                          onChange={(e) => HandleAddUrl(e)}
                                        />
                                      </i>
                                    </div>
                                  </div>
                                  <FormGroup className="col-md-12">
                                    <Label>{Name}</Label>
                                    <Row>
                                      <Col sm="6">
                                        <input
                                          className="form-control"
                                          name="name"
                                          type="text"
                                          {...register("name", {
                                            required: true,
                                          })}
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors.name &&
                                            "First name is required"}
                                        </span>
                                      </Col>
                                      <Col sm="6">
                                        <input
                                          className="form-control"
                                          name="surname"
                                          type="text"
                                          {...register("surname", {
                                            required: true,
                                          })}
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors.surname &&
                                            "Last name is required"}
                                        </span>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{Age}</Label>
                                    <input
                                      className="form-control"
                                      name="age"
                                      type="number"
                                      {...register("age", {
                                        required: true,
                                        pattern: /\d+/,
                                        min: 18,
                                        max: 70,
                                      })}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.age &&
                                        "Please enter age between 18 to 70 year."}
                                    </span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12 ">
                                    <Label>{Mobile}</Label>
                                    <input
                                      className="form-control"
                                      name="mobile"
                                      type="number"
                                      {...register("mobile", {
                                        pattern: /\d+/,
                                        minlength: 0,
                                        maxlength: 9,
                                      })}
                                    />
                                    <span style={{ color: "red" }}>
                                      {errors.mobile &&
                                        "Please enter number max 9 digit"}
                                    </span>
                                  </FormGroup>
                                </Row>
                                <Button color="secondary" className="me-1 mt-2">
                                  {Save}
                                </Button>
                                <Button color="primary mt-2" onClick={toggle}>
                                  {Cancel}
                                </Button>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </NavItem> */}
                        <NavItem>
                          <span className="main-title">Menu</span>
                        </NavItem>
                        <NavItem>
                          <a
                            href="#javascript"
                            className={
                              activeTab === "1" ? "active active-style" : ""
                            }
                            onClick={() => setActiveTab("1")}
                          >
                            <span
                              className={`title ${
                                activeTab === "1" && "active-color"
                              } `}
                            >
                              {" "}
                              Plant
                            </span>
                          </a>
                          <a
                            href="#javascript"
                            className={
                              activeTab === "2" ? "active active-style" : ""
                            }
                            onClick={() => setActiveTab("2")}
                          >
                            <span
                              className={`title ${
                                activeTab === "2" && "active-color"
                              } `}
                            >
                              {" "}
                              Block
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            href="#javascript"
                            className={
                              activeTab === "3" ? "active active-style" : ""
                            }
                            onClick={() => setActiveTab("3")}
                          >
                            <span
                              className={`title ${
                                activeTab === "3" && "active-color"
                              } `}
                            >
                              Inverter
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            href="#javascript"
                            className={
                              activeTab === "4" ? "active active-style" : ""
                            }
                            onClick={() => setActiveTab("4")}
                          >
                            <span
                              className={`title ${
                                activeTab === "4" && "active-color"
                              } `}
                            >
                              String
                            </span>
                          </a>
                        </NavItem>
                        <NavItem>
                          <a
                            href="#javascript"
                            className={
                              activeTab === "5" ? "active active-style" : ""
                            }
                            onClick={() => setActiveTab("5")}
                          >
                            <span
                              className={`title ${
                                activeTab === "5" && "active-color"
                              } `}
                            >
                              Modulues
                            </span>
                          </a>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            {/* list */}
            <Col xl="9" md="12" className="box-col-8">
              <div className="email-right-aside bookmark-tabcontent contacts-tabs">
                <div className="email-body radius-left">
                  <div className="ps-0">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>Plant</h5>
                            {/* <span className="f-14 pull-right mt-0">{`${users.length} Contacts`}</span> */}
                          </CardHeader>
                          <CardBody className="p-0">
                            <PlantTable />
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="2">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Orgenization}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody className="p-0">
                            <Row className="list-persons">
                              <Col xl="4 xl-50" md="5">
                                <Nav
                                  className="flex-column nav-pills"
                                  id="v-pills-tab1"
                                  role="tablist"
                                  aria-orientation="vertical"
                                >
                                  <NavItem id="myTab" role="tablist">
                                    <NavLink
                                      href="#javaScript"
                                      className={
                                        orgactiveTab === "1" ? "active" : ""
                                      }
                                      onClick={() => setorgActiveTab("1")}
                                    >
                                      <div className="media">
                                        <img
                                          className="img-50 img-fluid m-r-20 rounded-circle"
                                          src={require("../../../assets/images/user/user.png")}
                                          alt=""
                                        />
                                        <div className="media-body">
                                          <h6>{MarkJecno}</h6>
                                          <p>{MARKJENCOEMAIL}</p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem id="myTab" role="tablist">
                                    <NavLink
                                      href="#javaScript"
                                      className={
                                        orgactiveTab === "2" ? "active" : ""
                                      }
                                      onClick={() => setorgActiveTab("2")}
                                    >
                                      <div className="media">
                                        <img
                                          className="img-50 img-fluid m-r-20 rounded-circle"
                                          src={require("../../../assets/images/user/3.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body">
                                          <h6>{JasonBorne}</h6>
                                          <p>{"jasonb@gmail.com"}</p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem id="myTab" role="tablist">
                                    <a
                                      href="#javaScript"
                                      className={
                                        orgactiveTab === "3" ? "active" : ""
                                      }
                                      onClick={() => setorgActiveTab("3")}
                                    >
                                      <div className="media">
                                        <img
                                          className="img-50 img-fluid m-r-20 rounded-circle"
                                          src={require("../../../assets/images/user/4.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body">
                                          <h6>{SarahLoren}</h6>
                                          <p>{"barnes@gmail.com"}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </NavItem>
                                  <NavItem id="myTab" role="tablist">
                                    <a
                                      href="#javaScript"
                                      className={
                                        orgactiveTab === "4" ? "active" : ""
                                      }
                                      onClick={() => setorgActiveTab("4")}
                                    >
                                      <div className="media">
                                        <img
                                          className="img-50 img-fluid m-r-20 rounded-circle"
                                          src={require("../../../assets/images/user/10.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body">
                                          <h6>{AndewJon}</h6>
                                          <p>{"andrewj@gmail.com"}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </NavItem>
                                </Nav>
                              </Col>
                              <Col xl="8 xl-50" md="7">
                                <TabContent activeTab={orgactiveTab}>
                                  <TabPane tabId="1">
                                    <div className="profile-mail">
                                      <div className="media align-items-center">
                                        <img
                                          className="img-100 img-fluid m-r-20 rounded-circle update_img_5"
                                          src={require("../../../assets/images/user/user.png")}
                                          alt=""
                                        />
                                        <div className="media-body mt-0">
                                          <h5>
                                            <span className="first_name_5">
                                              {"Mark"}{" "}
                                            </span>
                                            <span className="last_name_5">
                                              {"jecno"}
                                            </span>
                                          </h5>
                                          <p className="email_add_5">
                                            {MARKJENCOEMAIL}
                                          </p>
                                          <ul>
                                            <li>
                                              <a
                                                href="#javaScript"
                                                data-toggle="modal"
                                                data-target="#printModal"
                                              >
                                                {Print}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="email-general">
                                        <h6>{General}</h6>
                                        <p>
                                          {EmailAddress}:{" "}
                                          <span className="font-primary email_add_5">
                                            {MARKJENCOEMAIL}
                                          </span>
                                        </p>
                                        <div className="gender">
                                          <h6>{Personal}</h6>
                                          <p>
                                            {Gender}: <span>{Male}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="2">
                                    <div className="profile-mail">
                                      <div className="media align-items-center">
                                        <img
                                          className="img-100 img-fluid m-r-20 rounded-circle update_img_6"
                                          src={require("../../../assets/images/user/3.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body mt-0">
                                          <h5>
                                            <span className="first_name_6">
                                              {"Jason"}{" "}
                                            </span>
                                            <span className="last_name_6">
                                              {"Borne"}
                                            </span>
                                          </h5>
                                          <p className="email_add_6">
                                            {"jasonb@gmail.com"}
                                          </p>
                                          <ul>
                                            <li>
                                              <a
                                                href="#javaScript"
                                                data-toggle="modal"
                                                data-target="#printModal"
                                              >
                                                {Print}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="email-general">
                                        <h6>{General}</h6>
                                        <p>
                                          {EmailAddress}:{" "}
                                          <span className="font-primary email_add_6">
                                            {"jasonb@gmail.com"}
                                          </span>
                                        </p>
                                        <div className="gender">
                                          <h6>{Personal}</h6>
                                          <p>
                                            {Gender}: <span>{Male}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="3">
                                    <div className="profile-mail">
                                      <div className="media align-items-center">
                                        <img
                                          className="img-100 img-fluid m-r-20 rounded-circle update_img_7"
                                          src={require("../../../assets/images/user/4.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body mt-0">
                                          <h5>
                                            {" "}
                                            <span className="first_name_7">
                                              {"Sarah"}{" "}
                                            </span>
                                            <span className="last_name_7">
                                              {"Loren"}
                                            </span>
                                          </h5>
                                          <p className="email_add_7">
                                            {"barnes@gmail.com"}
                                          </p>
                                          <ul>
                                            <li>
                                              <a
                                                href="#javaScript"
                                                data-toggle="modal"
                                                data-target="#printModal"
                                              >
                                                {Print}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="email-general">
                                        <h6>{General}</h6>
                                        <p>
                                          {EmailAddress}:{" "}
                                          <span className="font-primary email_add_7">
                                            {"barnes@gmail.com"}
                                          </span>
                                        </p>
                                        <div className="gender">
                                          <h6>{Personal}</h6>
                                          <p>
                                            {Gender}: <span>{Female}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane tabId="4">
                                    <div className="profile-mail">
                                      <div className="media align-items-center">
                                        <img
                                          className="img-100 img-fluid m-r-20 rounded-circle update_img_8"
                                          src={require("../../../assets/images/user/10.jpg")}
                                          alt=""
                                        />
                                        <div className="media-body mt-0">
                                          <h5>
                                            {" "}
                                            <span className="first_name_8">
                                              {"Andew"}{" "}
                                            </span>
                                            <span className="last_name_8">
                                              {"Jon"}
                                            </span>
                                          </h5>
                                          <p className="email_add_8">
                                            {"andrewj@gmail.com"}
                                          </p>
                                          <ul>
                                            <li>
                                              <a
                                                href="#javaScript"
                                                data-toggle="modal"
                                                data-target="#printModal"
                                              >
                                                {Print}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="email-general">
                                        <h6>{General}</h6>
                                        <p>
                                          {EmailAddress}:{" "}
                                          <span className="font-primary email_add_8">
                                            {"andrewj@gmail.com"}
                                          </span>
                                        </p>
                                        <div className="gender">
                                          <h6>{Personal}</h6>
                                          <p>
                                            {Gender}: <span>{Female}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                </TabContent>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="3">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{FollowUp}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="4">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Favourites}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="5">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Ideas}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="6">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Important}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="7">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Business}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="8">
                        <Card>
                          <CardHeader className="d-flex">
                            <h5>{Holidays}</h5>
                            <span className="f-14 pull-right mt-0">
                              {"10 Contacts"}
                            </span>
                          </CardHeader>
                          <CardBody>
                            <p>{NoDataFound} </p>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <div id="right-history" className="history">
                        <div className="modal-header p-l-20 p-r-20">
                          <h6 className="modal-title w-100">
                            {ContactHistory}
                            <span className="pull-right">
                              <a
                                className="closehistory"
                                href="#javaScript"
                                onClick={closehistory}
                              >
                                <i className="icofont icofont-close"></i>
                              </a>
                            </span>
                          </h6>
                        </div>
                        <div className="history-details">
                          <div className="text-center">
                            <i className="icofont icofont-ui-edit"></i>
                            <p>{"Contact has not been modified yet."}</p>
                          </div>
                          <div className="media">
                            <i className="icofont icofont-star me-3"></i>
                            <div className="media-body mt-0">
                              <h6 className="mt-0">{"Contact Created"}</h6>
                              <p className="mb-0">
                                {"Contact is created via mail"}
                              </p>
                              <span className="f-12">
                                {"Feb 10, 2022 4:00"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedUser ? (
                        <Modal
                          className="modal-bookmark"
                          isOpen={printmodal}
                          toggle={printModalToggle}
                        >
                          <ModalHeader toggle={printModalToggle}>
                            {PrintViews}
                          </ModalHeader>
                          <ModalBody className="list-persons">
                            {/* <PrintPreview
                              selectedUser={selectedUser}
                              ref={componentRef}
                            /> */}
                            {/* <ReactToPrint
                              trigger={() => (
                                <Button color="secondary" className="me-1 mt-2">
                                  {Print}
                                </Button>
                              )}
                              content={() => componentRef.current}
                            /> */}

                            <Button
                              color="primary mt-2"
                              onClick={printModalToggle}
                            >
                              {Cancel}
                            </Button>
                          </ModalBody>
                        </Modal>
                      ) : (
                        ""
                      )}
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default SolarMonitoring;
