import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  plantName,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import { createPlant, editModule, getStringListData } from "../../../../api";
import { toast } from "react-toastify";

export const EditModuleModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
  moduleId,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [stringData, setStringData] = useState([]);
  const [stringId, setStringId] = useState("");

  const loadStringData = async () => {
    let data = await getStringListData();
    setStringData(data || []);
  };

  const loadData = async () => {
    loadStringData();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let data = new FormData(e.target);

      var formData = {};
      data.forEach((value, key) => {
        formData[key] = value;
      });

      const userToken = localStorage.getItem("token");
      let decodedUser = jwtDecode(userToken);

      const payload = {
        string_id: formData.string_id,
      };

      const plant = await editModule(payload, moduleId);
      setRefetch(true);
      setIsLoading(false);
      e.target.reset();
      setShowModal(false);
    } catch (error) {
      toast.error(`${error.message}`);
      console.log(error);
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const stringListOptions = stringData?.map((item, index) => {
    return (
      <option value={item?._id} key={index}>
        {item?.name}
      </option>
    );
  });

  console.log(stringData, "string data");

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="lg">
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <ModalHeader toggle={Modaltoggle}>{"Remap Module"}</ModalHeader>
          <ModalBody>
            <CardBody>
              <Row>
                <Col md="12 mb-3">
                  <select className="form-control" name="string_id">
                    <option value={null}>Select String to remap</option>
                    {stringListOptions}
                  </select>
                  <span>{errors.string_id && "String is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>
            </CardBody>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={Modaltoggle}>
              {Close}
            </Button>
            <Button
              style={isLoading ? { cursor: "not-allowed" } : {}}
              className={isLoading && `disabled`}
              color="primary"
              type="submit"
            >
              {isLoading ? "Remapping..." : "Remap"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default EditModuleModal;
