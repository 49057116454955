import React, { useState } from "react";
import Inverterchart from "./inverter/InverterChart";
import { Plus } from "react-feather";
import AddInverterModal from "./inverter/AddInverterModal";
import InverterTable from "./inverter/InverterTable";

function Inverter() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);
  return (
    <div>
      <Inverterchart />
      <div className="container">
        <div className="d-flex justify-content-between mb-3 mt-3 align-items-center">
          <h5>Inverter Details</h5>
          <button
            onClick={Modaltoggle}
            style={{ border: "none", borderRadius: "5px" }}
            className="badge-light-primary btn-mail d-flex p-2 align-items-center"
          >
            <Plus className="me-2" />
            Add Inverter
          </button>
          <AddInverterModal
            showModal={showModal}
            setShowModal={setShowModal}
            Modaltoggle={Modaltoggle}
            setRefetch={setRefetch}
          />
        </div>
      </div>
      <InverterTable refetch={refetch} />
    </div>
  );
}

export default Inverter;
