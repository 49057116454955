import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
  stringName,
  inverterId,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import { BASE_API_URL, getInverterListData } from "../../../../api";
import axios from "axios";
import { toast } from "react-toastify";
import { useLoaderData } from "react-router";

const AddStringModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [inverterData, setInverterData] = useState([]);

  const loadInverterData = async () => {
    let data = await getInverterListData();
    setInverterData(data.data.data || []);
  };

  const loadData = async () => {
    loadInverterData();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let data = new FormData(e.target);

      var formData = {};
      data.forEach((value, key) => {
        formData[key] = value;
      });

      const payload = {
        name: formData.name,
        inverter_id: formData.inverter_id,
        isActive: true,
        address: {
          location: {
            type: "Point",
            coordinates: [],
          },
          name: formData.address,
          local_area: formData.local_area,
          village: formData.village,
          taluka: formData.taluka,
          district: formData.district,
          state: formData.state,
          country: formData.country,
          pincode: formData.pincode,
          raw_address: "",
        },
      };

      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_API_URL}/string`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "success") {
        toast.success("String added successfully!");
        setIsLoading(false);
        setRefetch(true);
      }

      setShowModal(false);
    } catch (error) {
      toast.error(`${error.message}`);
      setShowModal(false);
      setIsLoading(false);
    }
  };

  const invertListOptions = inverterData.map((item, index) => {
    return (
      <option value={item?._id} key={index}>
        {item?.serial_number}
      </option>
    );
  });

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={Modaltoggle}>{"String Details"}</ModalHeader>
          <ModalBody>
            <CardBody>
              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{stringName}</Label>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    placeholder="String Name"
                  />
                  <span>{errors.stringName && "String Name is required"}</span>
                  <div className="valid-feedback">
                    {"String Name is required"}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{inverterId}</Label>
                  <select className="form-control" name="inverter_id">
                    <option value={null}>Select Inverter</option>
                    {invertListOptions}
                  </select>
                </Col>
              </Row>

              <Row>
                <h5>String Address Details</h5>
                {/* location coordinates */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{location}</Label>
                  <input
                    className="form-control"
                    name="location"
                    type="text"
                    placeholder="Location"
                    // {...register("location", { required: true })}
                  />
                  {/* <span>
                      {errors.location && "Please provide location coordinates"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide location coordinates."}
                    </div> */}
                </Col>
                {/* address Name */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom04">{addressName}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="address"
                    type="text"
                    placeholder="Address Name"
                  />
                  {/* <span>
                      {errors.addressName && "Please Provide Address Name"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a Address Name"}
                    </div> */}
                </Col>
                {/* local area */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom05">{localArea}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="local_area"
                    type="text"
                    placeholder="Local Area"
                    // {...register("localArea", { required: true })}
                  />
                  {/* <span>
                      {errors.localArea && "Please provide a Local Area."}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a Local Area."}
                    </div> */}
                </Col>
              </Row>
              <Row>
                {/* village */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{village}</Label>
                  <input
                    className="form-control"
                    name="village"
                    type="text"
                    placeholder="Village"
                    // {...register("village", { required: true })}
                  />
                  {/* <span>
                      {errors.location && "Please provide a Village Details"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a Village Details"}
                    </div> */}
                </Col>
                {/* taluka */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{taluka}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="taluka"
                    type="text"
                    placeholder="Taluka"
                    // {...register("taluka", { required: true })}
                  />
                  <span>
                    {errors.addressName && "Please Provide taluka details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide taluka details"}
                  </div>
                </Col>
                {/* district*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{district}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="district"
                    type="text"
                    placeholder="District"
                    // {...register("district", { required: true })}
                  />
                  {/* <span>
                      {errors.district && "Please provide a District Name"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a District Name."}
                    </div> */}
                </Col>
              </Row>
              <Row>
                {/* State */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{state}</Label>
                  <input
                    className="form-control"
                    name="state"
                    type="text"
                    placeholder="State"
                  />
                  {/* <span>
                      {errors.State && "Please provide a State Details"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a State Details"}
                    </div> */}
                </Col>
                {/* Country */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{country}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="country"
                    type="text"
                    placeholder="Country"
                    // {...register("country", { required: true })}
                  />
                  {/* <span>
                      {errors.country && "Please Provide a Country name"}
                    </span>
                    <div className="invalid-feedback">
                      {"Please provide a country name"}
                    </div> */}
                </Col>
                {/* pincode/zipcode*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{zipCode}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="pincode"
                    type="text"
                    placeholder="Zip Code"

                    // {...register("zipCode", { required: true })}
                  />
                  {/* <span>{errors.zipCode && "Please provide a zip code"}</span>
                    <div className="invalid-feedback">
                      {"Please provide a zipcode/Pincode."}
                    </div> */}
                </Col>
              </Row>
              <FormGroup>
                <div className="form-check">
                  {/* <div className="checkbox p-0">
                      <Input
                        className="form-check-input"
                        id="invalidCheck"
                        type="checkbox"
                      />
                      <Label className="form-check-label" htmlFor="invalidCheck">
                        {"Agree to terms and conditions"}
                      </Label>
                    </div> */}
                  {/* <div className="invalid-feedback">
                      {"You must agree before submitting."}
                    </div> */}
                </div>
              </FormGroup>
              {/* <Button color="primary" type="submit">{"Submit form"}</Button> */}
            </CardBody>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={Modaltoggle}>
              {Close}
            </Button>
            <Button
              style={isLoading ? { cursor: "not-allowed" } : {}}
              className={isLoading && `disabled`}
              color="primary"
              type="submit"
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AddStringModal;
