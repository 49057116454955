import axios from "axios";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap";
import { BASE_API_URL, getReports } from "../../../../api";
import Breadcrumbs from "../../../../layout/breadcrumb";
import AddReportModal from "./AddReportModal";
import { dateFormat } from "./ModulesDetails";

const TestDetails = () => {
  const { id } = useParams();
  const [testDetails, setTestDetails] = useState([]);
  const [reports, setReports] = useState([]);
  const [refetch, setRefetch] = useState(false);

  console.log(testDetails, "testDetails");

  const { user } = useSelector((state) => state?.auth);

  const adaniUser = user && jwtDecode(user);

  const getTestDetails = async () => {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${BASE_API_URL}/test/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setTestDetails(res.data.data);
  };

  const getAllReports = async () => {
    let reports = await getReports();
    setReports(reports?.data?.data);
  };

  useEffect(() => {
    try {
      getTestDetails();
      getAllReports();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      getTestDetails();
      getAllReports();
    }
  }, [refetch]);

  const [images, setImage] = useState([]);
  const [smallImages, setsmallImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  const initilindex = { index: 0, isOpen: false };
  const [photoIndex, setPhotoIndex] = useState(initilindex);
  const onMovePrev = () => {
    const prev = (photoIndex.index + images.length - 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: prev });
  };
  const onMoveNext = () => {
    const next = (photoIndex.index + 1) % images.length;
    setPhotoIndex({ ...photoIndex, index: next });
  };

  console.log(reports, "report");

  return (
    <Fragment>
      <Breadcrumbs title="Test Details" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              {/* test type one - FLASH */}
              <Card>
                {testDetails?.type === "FLASH" ? (
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="9" className="media-body align-self-center">
                          <h1 className="mt-0 user-name">{"Test Details"}</h1>
                        </Col>
                        <Col sm="3"></Col>
                      </Row>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Test ID</h6>
                          <span>{testDetails?._id}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Type</h6>
                          <span>{testDetails?.type}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Date</h6>
                          <span>{dateFormat(testDetails?.date)}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Testing Irradiance</h6>
                          <span>{`${testDetails?.testing_irradiation} ${testDetails?.irradiation_unit}`}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Testing Temperature</h6>
                          <span>{`${testDetails?.testing_temperature} ${testDetails?.temperature_unit}`}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>voc</h6>
                          <span>{testDetails?.voc}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>isc</h6>
                          <span>{testDetails?.isc}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>vmp</h6>
                          <span>{testDetails?.vmp}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>imp</h6>
                          <span>{testDetails?.imp}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Form factor</h6>
                          <span>{testDetails?.form_factor}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Comments</h6>
                          <span>{testDetails?.comments}</span>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>

              <Card>
                {testDetails?.type === "EL" ? (
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="9" className="media-body align-self-center">
                          <h1 className="mt-0 user-name">{"Test Details"}</h1>
                        </Col>
                        <Col sm="3"></Col>
                      </Row>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Test ID</h6>
                          <span>{testDetails?._id}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Type</h6>
                          <span>{testDetails?.type}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Date</h6>
                          <span>{dateFormat(testDetails?.date)}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Micro Cracks Precent</h6>
                          <span>{`${testDetails?.micro_cracks_percent}`}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Cracks Percent</h6>
                          <span>{`${testDetails?.cracks_percent}`}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Spiders Count</h6>
                          <span>{testDetails?.spiders_count}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Dead Cell Count</h6>
                          <span>{testDetails?.dead_cell_count}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Current Mismatch</h6>
                          <span>{testDetails?.current_mismatch}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Back Sheet Scratch</h6>
                          <span>{testDetails?.back_sheet_scratch}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Bypass Diode</h6>
                          <span>{testDetails?.bypass_diode}</span>
                        </div>
                      </Col>
                    </Row>

                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>Soldering Issue</h6>
                        <span>{testDetails?.soldering_issue}</span>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>Comments</h6>
                        <span>{testDetails?.comments}</span>
                      </div>
                    </Col>

                    <Row></Row>
                    <hr></hr>

                    {/* <Row>
                          <h6>Attachments</h6>
                          {
                              testDetails?.attachments === [] ? (
                                  <div>No Attachments found</div>
                              ) : (
                                  <Card>
                                  <CardHeader>
                                    
                                  </CardHeader>
                                  <CardBody className="my-gallery row">
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                              src={require(`./image/test1.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:0, isOpen:true})
                                              }
                                          />
                                      </figure>
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                              src={require(`./image/test2.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:1, isOpen:true})
                                              }
                                          />
                                      </figure>
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                             src={require(`./image/test3.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:2, isOpen:true})
                                              }
                                          />
                                      </figure>
  
  
  
                                  </CardBody>
                              </Card>
                              )
                          }
                      </Row> */}
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>

              <Card>
                {testDetails?.type === "THERMOGRAPHY" ? (
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="9" className="media-body align-self-center">
                          <h1 className="mt-0 user-name">{"Test Details"}</h1>
                        </Col>
                        <Col sm="3"></Col>
                      </Row>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Test ID</h6>
                          <span>{testDetails?._id}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Type</h6>
                          <span>{testDetails?.type}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Date</h6>
                          <span>{dateFormat(testDetails?.date)}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Max Temperature</h6>
                          <span>{`${testDetails?.max_temperature}`}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Min Temperature</h6>
                          <span>{`${testDetails?.min_temperature}`}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Average Temperature</h6>
                          <span>{testDetails?.average_temperature}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Temperature Difference</h6>
                          <span>{testDetails?.temperature_difference}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Hotspot Severity</h6>
                          <span>{testDetails?.hotspot_severity}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Comments</h6>
                          <span>{testDetails?.comments}</span>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>

                    {/* <Row>
                          <h6>Attachments</h6>
                          {
                              testDetails?.attachments === [] ? (
                                  <div>No Attachments found</div>
                              ) : (
                                  <Card>
                                  <CardHeader>
                                    
                                  </CardHeader>
                                  <CardBody className="my-gallery row">
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                              src={require(`./image/test1.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:0, isOpen:true})
                                              }
                                          />
                                      </figure>
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                              src={require(`./image/test2.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:1, isOpen:true})
                                              }
                                          />
                                      </figure>
                                      <figure className="col-xl-3 col-sm-6">
                                          <Media
                                             src={require(`./image/test3.jpeg`)}
                                              alt="Gallery"
                                              className="img-thumbnail"
                                              onClick={() =>
                                                  setPhotoIndex({ ...photoIndex,index:2, isOpen:true})
                                              }
                                          />
                                      </figure>
  
  
  
                                  </CardBody>
                              </Card>
                              )
                          }
                      </Row> */}
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>

              <Card>
                {testDetails?.type === "IV-CURVE" ? (
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="9" className="media-body align-self-center">
                          <h1 className="mt-0 user-name">{"Test Details"}</h1>
                        </Col>
                        <Col sm="3"></Col>
                      </Row>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Test ID</h6>
                          <span>{testDetails?._id}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Type</h6>
                          <span>{testDetails?.type}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Test Date</h6>
                          <span>{dateFormat(testDetails?.date)}</span>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="ttl-info text-start ttl-sm-mb-0">
                          <h6>Testing Irradiance</h6>
                          <span>{`${testDetails?.testing_irradiation} ${testDetails?.irradiation_unit}`}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Testing Temperature</h6>
                          <span>{`${testDetails?.testing_temperature} ${testDetails?.temperature_unit}`}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>voc</h6>
                          <span>{testDetails?.voc}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>isc</h6>
                          <span>{testDetails?.isc}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>vmp</h6>
                          <span>{testDetails?.vmp}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>imp</h6>
                          <span>{testDetails?.imp}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Form factor</h6>
                          <span>{testDetails?.form_factor}</span>
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="ttl-info text-start">
                          <h6>Comments</h6>
                          <span>{testDetails?.comments}</span>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>

              <Row>
                <h6>Attachments</h6>
                {testDetails?.attachments?.length < 0 ? (
                  <div>No Attachments found</div>
                ) : (
                  <Card>
                    <CardBody className="my-gallery row">
                      {testDetails?.attachments?.map((el) => {
                        return (
                          <figure className="col-xl-3 col-sm-6">
                            <Media
                              key={el?._id}
                              src={`https://adani-module.infura-ipfs.io/ipfs/${el?.path}`}
                              alt="Gallery"
                              className="img-thumbnail"
                              onClick={() =>
                                setPhotoIndex({
                                  ...photoIndex,
                                  index: 0,
                                  isOpen: true,
                                })
                              }
                            />
                          </figure>
                        );
                      })}
                    </CardBody>
                  </Card>
                )}
              </Row>
            </Col>
          </Row>

          <Row>
            <Row sm="12" className="align-items-center">
              <Col sm="9" className="media-body align-evenly">
                <h5 className="mt-3 user-name">{"Reports"}</h5>
              </Col>
              {!adaniUser?.roles?.includes("ADMIN") && (
                <Col sm="3" className="mb-3" onClick={Modaltoggle}>
                  <Button color="primary">Add Report</Button>
                </Col>
              )}
              <AddReportModal
                showModal={showModal}
                setShowModal={setShowModal}
                Modaltoggle={Modaltoggle}
                testDetails={testDetails}
                setRefetch={setRefetch}
              />
            </Row>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table borderless>
                    <thead>
                      <tr>
                        <th scope="col">{"#"}</th>
                        <th scope="col">{"Report"}</th>
                        <th scope="col">{"Type"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                              <a
                                href={`https://adani-module.infura-ipfs.io/ipfs/${data?.attachment?.path}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                
                                {data?.attachment?.path}
                              </a>
                            </td>
                            <td>
                              {data?.attachment?.type?.match(/image\/*/) !== null
                                ? "Image"
                                : "PDF"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default TestDetails;
