import { createStore, applyMiddleware } from "redux";
import reducers from "../redux/index";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const middleware = [thunk];

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(...middleware),
    window.devToolsExtension
      ? window.devToolsExtension()
      : function (f) {
          return f;
        }
  )
);

export default store;
