import {SET_ACTIVE_PLANT} from '../actionTypes';

const initial_state = {
  plantId: null,
};

const plantReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACTIVE_PLANT:
      return {...state, plantId: action.payload.plantId};

    default:
      return {...state};
  }
};
export default plantReducer;
