import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

export const PrivateRoute = () => {
  // const { isLoggedIn } = useSelector((state) => state?.auth);
  const jwtToken = localStorage.getItem("token");

  return jwtToken ? <Outlet /> : <Navigate to="/login" />;
};

export const AuthRoute = () => {
  // const { isLoggedIn } = useSelector((state) => state?.auth);
  const jwtToken = localStorage.getItem("token");

  return jwtToken ? <Navigate to="/" /> : <Outlet />;
};
