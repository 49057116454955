import { Fragment, useEffect } from "react";
import { Edit } from "react-feather";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import { getClaimsData } from "../../../../redux/auth/action";
import { dateFormat } from "../modules/ModulesDetails";
import Breadcrumbs from "../../../../layout/breadcrumb";

export const WarrantyClaimsTables = () => {
  const dispatch = useDispatch();
  const claimsList = useSelector((state) => state?.auth?.claimsData?.data);
  const getData = async () => {
    dispatch(getClaimsData());
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      <Breadcrumbs link="/claims" title="Claims" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>Warranty Claims</CardHeader>

              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Claim ID"}</th>
                      <th scope="col">{"Timestamp"}</th>
                      <th scope="col">{"Submitted By"}</th>
                      <th scope="col">{"Module Serial Number"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col">{"More Details"}</th>
                    </tr>
                  </thead>
                  <tbody>
                      {
                        claimsList?.map((claim, index) => {
                          return(
                            <tr>
                            <th scope="row">{index+1}</th>
                            <td><Link to={`/claims/${claim?._id}`}>{claim?._id}</Link></td>
                            <td>{dateFormat(claim?.timestamp)}</td>
                            <td>{claim?.user?.name}</td>
                            <td>{claim?.module?.serial_number}</td>
                            <td>{claim?.status}</td>
                            <td>{claim?.description}</td>
                           
                          </tr>
  
                          )
                        })
                      }
  
                    </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
