import axios from "axios";
import jwtDecode from "jwt-decode";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap";
import { BASE_API_URL, getWarrantyClaimDetails } from "../../../../api";
import Breadcrumbs from "../../../../layout/breadcrumb";
import { dateFormat } from "../modules/ModulesDetails";
import { AddAttachmentsModal } from "./AddAttachmentsModal";
import { AddWarrantyTest } from "./AddWarrantyTest";
import { ApproveClaim } from "./ApproveClaimModal";
import { OnholdClaim } from "./OnholdClaim";
import { RejectClaim } from "./RejectClaim";

export const WarrantyDetails = () => {
  const { id } = useParams();
  const [claimDetails, setClaimDetails] = useState({});
  const [disableApproveButton, setDisableApproveButton] = useState(false);
  const [disableRejectButton, setDisableRejectButton] = useState(false);
  const [disableOnholdButton, setdisableOnholdbutton] = useState(false);
  // show modal for test
  const [showModal, setShowModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

 

  //show modal for Attachments
  const [showAttachmentModal, setshowAttachmentModal] = useState();
  const attachmentModal = () => setshowAttachmentModal(!showAttachmentModal);

  //show modal for approve claim
  const [showApproveModal, setShowApproveModal] = useState();
  const approveModal = () => setShowApproveModal(!showApproveModal);

  //show modal for approve claim
  const [showRejectModal, setShowRejectModal] = useState();
  const rejectModal = () => setShowRejectModal(!showRejectModal);

  //show Modal for on hold claim
  const [showOnholdClaim, setShowOnholdClaim] = useState();
  const onholdModal = () => setShowOnholdClaim(!showOnholdClaim);

  const getData = async () => {
    const res = await getWarrantyClaimDetails(id);
    setClaimDetails(res.data);
  };

  const { user } = useSelector((state) => state?.auth);

  const adaniUser = user && jwtDecode(user);

  const [warrantyTests, setWarrantyTests] = useState([]);

  //fetch the comments on claim
  const [claimComments, setClaimComments] = useState([]);
  console.log(claimComments, "claimComments");
  const fetchCommentsOnClaim = async () => {
    let token = localStorage.getItem("token");
    try {
      const res = await axios.get(`${BASE_API_URL}/comment?claim_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClaimComments(res.data.data);
    } catch (error) {
      toast.error("Error while fetching Data");
    }
  };

  console.log(warrantyTests, "warranty Tests");
  const getWarrantyTests = async () => {
    let token = localStorage.getItem("token");
    try {
      const { data } = await axios.get(`${BASE_API_URL}/test?claim_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWarrantyTests(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getData();
    getWarrantyTests();
    fetchCommentsOnClaim();
  }, [id]);

  const handleApproveClaim = async (comments) => {
    let token = localStorage.getItem("token");
    let finalObj = {
      claim_id: id,
      message: comments,
    };
    try {
      axios
        .all([
          axios.patch(
            `${BASE_API_URL}/claim/${id}`,
            { status: "APPROVED" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          axios.post(`${BASE_API_URL}/comment`, finalObj, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((data1, data2) => {
            console.log(data1, "data1 claim", data2, "data2 comment");
          })
        );

      setShowApproveModal(false);
      toast.success("Claim Approved Successfully!");
      setDisableApproveButton(true);
    } catch (error) {
      toast.error("Error occurred while submitting");
    }
  };

  const handleRejectClaim = async (comments) => {
    let token = localStorage.getItem("token");
    let finalObj = {
      claim_id: id,
      message: comments,
    };
    try {
      axios
        .all([
          axios.patch(
            `${BASE_API_URL}/claim/${id}`,
            { status: "REJECTED" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          axios.post(`${BASE_API_URL}/comment`, finalObj, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((data1, data2) => {
            console.log(data1, "data1 claim", data2, "data2 comment");
          })
        );

      setShowRejectModal(false);
      toast.success("Claim Rejected Successfully!");
      setDisableRejectButton(true);
    } catch (error) {
      toast.error("Error occurred while submitting");
    }
  };

  const handleOnholdClaim = async (comments) => {
    let token = localStorage.getItem("token");
    let finalObj = {
      claim_id: id,
      message: comments,
    };
    try {
      axios
        .all([
          axios.patch(
            `${BASE_API_URL}/claim/${id}`,
            { status: "PENDING" },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          axios.post(`${BASE_API_URL}/comment`, finalObj, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ])
        .then(
          axios.spread((data1, data2) => {
            console.log(data1, "data1 claim", data2, "data2 comment");
          })
        );

      setShowOnholdClaim(false);
      setdisableOnholdbutton(true);
      toast.success("Claim put on-hold Successfully!");
    } catch (error) {
      toast.error("Error occurred while submitting");
    }
  };

  return (
    <Fragment>
      <Breadcrumbs title="Claim Details" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Row sm="12">
                      <Col sm="6" className="media-body align-self-center">
                        <h1 className="mt-0 user-name">{"Test Details"}</h1>
                      </Col>

                      {!adaniUser?.roles?.includes("ADMIN") &&
                        (claimDetails?.status === "APPROVED" ||
                        claimDetails?.status === "REJECTED" ? (
                          <Col sm="6">{`THIS CLAIM HAS BEEN ${claimDetails?.status}`}</Col>
                        ) : (
                          <>
                            <Col sm="3">
                              <Button color="primary" onClick={Modaltoggle}>
                                Add Test
                              </Button>
                            </Col>
                            <Col sm="3">
                              <Button color="primary" onClick={attachmentModal}>
                                Add Attachments
                              </Button>
                            </Col>
                          </>
                        ))}
                      {adaniUser?.roles?.includes("ADMIN") &&
                        (claimDetails?.status === "APPROVED" ||
                        claimDetails?.status === "REJECTED" ? (
                          <Col sm="6">
                            <div>{`THIS CLAIM HAS BEEN ${claimDetails?.status}`}</div>
                          </Col>
                        ) : (
                          <>
                            <Col sm="2">
                              <Button
                                color="primary"
                                onClick={approveModal}
                                disabled={
                                  disableApproveButton || disableRejectButton
                                }
                              >
                                Approve Claim
                              </Button>
                              <ApproveClaim
                                approveModal={approveModal}
                                setShowApproveModal={setShowApproveModal}
                                showApproveModal={showApproveModal}
                                claimID={id}
                                handleApproveClaim={handleApproveClaim}
                              />
                            </Col>
                            <Col sm="2">
                              <Button
                                color="secondary"
                                onClick={rejectModal}
                                disabled={
                                  disableRejectButton || disableApproveButton
                                }
                              >
                                Reject Claim
                              </Button>
                              <RejectClaim
                                rejectModal={rejectModal}
                                setShowRejectModal={setShowRejectModal}
                                showRejectModal={showRejectModal}
                                claimID={id}
                                handleRejectClaim={handleRejectClaim}
                              />
                            </Col>
                            <Col sm="2">
                              <Button
                                color="primary"
                                onClick={onholdModal}
                                disabled={disableOnholdButton}
                              >
                                Claim On-Hold
                              </Button>
                              <OnholdClaim
                                onholdModal={onholdModal}
                                setShowOnholdClaim={setShowOnholdClaim}
                                showOnholdClaim={showOnholdClaim}
                                handleOnholdClaim={handleOnholdClaim}
                              />
                            </Col>
                          </>
                        ))}
                      <AddWarrantyTest
                        showModal={showModal}
                        setShowModal={setShowModal}
                        Modaltoggle={Modaltoggle}
                        moduleId={claimDetails?.module_id}
                      />
                      <AddAttachmentsModal
                        showAttachmentModal={showAttachmentModal}
                        setshowAttachmentModal={setshowAttachmentModal}
                        attachmentModal={attachmentModal}
                        claimID={id}
                      />
                    </Row>
                  </Row>

                  <hr />

                  <Row>
                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>Claim ID</h6>
                        <span>{claimDetails?._id}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Claim Status</h6>
                        <span>{claimDetails?.status}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Submitted On</h6>
                        <span>{dateFormat(claimDetails?.timestamp)}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Submitted By</h6>
                        <span>{`${claimDetails?.user?.name}`}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Module Serial Number</h6>
                        <span>{`${claimDetails?.module?.serial_number}`}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>Claim Description</h6>
                        <span>{`${claimDetails?.description}`}</span>
                      </div>
                    </Col>
                  </Row>

                  <hr></hr>
                </div>
              </Card>

              <Col>
                <h6>Attachments</h6>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">{"#"}</th>
                            <th scope="col">{"Report"}</th>
                            <th scope="col">{"Type"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {claimDetails?.attachments?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>
                                  <a
                                    href={`https://adani-module.infura-ipfs.io/ipfs/${data?.path}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    {data?.path}
                                  </a>
                                </td>
                                <td>
                                  {data?.type.match(/image\/*/) !== null
                                    ? "Image"
                                    : "PDF"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              {/* Comments on the Claim by admin */}
              {!adaniUser?.roles?.includes("ADMIN") && (
                <Card>
                  <CardHeader>Comments</CardHeader>

                  <div className="table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"#"}</th>
                          <th scope="col">{"Timestamp"}</th>

                          <th scope="col">{"Status"}</th>
                          <th scope="col">{"Comments"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {claimComments?.map((claim, index) => {
                          return (
                            <tr>
                              <th scope="row">{index + 1}</th>

                              <td>{dateFormat(claim?.updatedAt)}</td>

                              <td>{claim?.claim?.status}</td>
                              <td>{claim?.message}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              )}

              <Row>
                <Col xl="12">
                  <Card>
                    <div className="profile-img-style">
                      <Row>
                        <Row sm="12">
                          <Col sm="8" className="media-body align-self-center">
                            <h5 className="mt-0 user-name">
                              {"Claim Test list"}
                            </h5>
                          </Col>
                        </Row>

                        <div className="table-responsive">
                          <Table borderless>
                            <thead>
                              <tr>
                                <th scope="col">{"#"}</th>
                                <th scope="col">{"Test Name"}</th>
                                <th scope="col">{"Timestamp"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {warrantyTests?.map((test, index) => {
                                return (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <Link to={`/tests/${test?._id}`}>
                                        {test?.type}
                                      </Link>
                                    </td>
                                    <td>{dateFormat(test?.date)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <hr />
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
