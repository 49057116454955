import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  TRACE_DATA,
  FETCH_FAIL,
  GET_CLAIMS_DATA,
} from "./../actionTypes";
import * as auth from "../../api";

export const login = (email, password) => (dispatch) => {
  return auth.loginAuth(email, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getTraceData = (moduleId) => (dispatch) => {
  return auth.getModulesDetails(moduleId).then(
    (data) => {
      dispatch({
        type: TRACE_DATA,
        payload: { moduleTraceData: data },
      });
      console.log(data, "data");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getClaimsData = () => (dispatch) => {
  return auth.getAllClaimsData().then(
    (data) => {
      dispatch({
        type: GET_CLAIMS_DATA,
        payload: { claimsData: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const logoutAuth = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      auth.logout();

      dispatch({
        type: LOGOUT,
      });
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};
