import React, { Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  Form,
  Button,
  Media,
} from "reactstrap";
import { Examples } from "../../../constant";
import Timeline1 from "../../bonus_ui/timelines/timeline1";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getTraceData } from "../../../redux/auth/action";

const Traceablity = () => {
  const dispatch = useDispatch();

  const [serialNumber, setSerialNumber] = useState("");
  const [traceData, setTraceData] = useState(null);
  const [production, setProduction] = useState(null);
  const [sale, setSale] = useState(null);
  const [transport, setTransport] = useState(null);
  const [installation, setInstallation] = useState(null);

  const getTrace = async () => {
    console.log(serialNumber, "serialNumber");
    if (serialNumber) {
      dispatch(getTraceData(serialNumber));
    }
  };

  return (
    <Fragment>
      <Breadcrumb link="/traceablity" title="Traceability" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Form inline>
                  <FormGroup
                    className="mb-2 mr-sm-2 mb-sm-0"
                    style={{ textAlign: "center" }}
                  >
                    <Row className="m-auto ">
                      <Col xs="9">
                        <Input
                          type="text"
                          name="text"
                          id=""
                          placeholder="Module Series Number"
                          onChange={(e) => setSerialNumber(e.target.value)}
                        />
                      </Col>
                      <Col xs="3">
                        <Button
                          color="primary"
                          size="lg"
                          block
                          onClick={() => getTrace()}
                        >
                          Show Trace
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </CardHeader>
              <CardBody>
                {serialNumber ? (
                  <Timeline1 />
                ) : (
                  <div className="search-not-found text-center ng-star-inserted">
                    <div className="">
                      <p className="mb-0">
                        {"Please Enter Module Serial Number"}
                      </p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col sm="12">
            {/* <Card>
              <CardHeader>
                <h5>
                  {Examples} {"2"}
                </h5>
              </CardHeader>
              <CardBody>
                <VerticalTimelineComp />
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Traceablity;
