import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  plantName,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import {
  createPlant,
  DeleteModule,
  editModule,
  getStringListData,
} from "../../../../api";
import { toast } from "react-toastify";

export const DeleteModuleModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
  moduleId,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const trash = await DeleteModule(moduleId);
      setRefetch(true);
      setIsLoading(false);
      e.target.reset();
      setShowModal(false);
    } catch (error) {
      toast.error(`${error.message}`);
      console.log(error);
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="lg">
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <ModalHeader toggle={Modaltoggle}>{"Delete Module"}</ModalHeader>
          <ModalBody>
            <CardBody>
              <Row>
                <Col md="12 text-center mb-3">
                  <Label style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Are you sure you want to delete this module
                  </Label>
                </Col>
              </Row>
            </CardBody>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={Modaltoggle}>
              {Close}
            </Button>
            <Button
              style={isLoading ? { cursor: "not-allowed" } : {}}
              className={isLoading && `disabled`}
              color="primary"
              type="submit"
            >
              {isLoading ? "Deleting..." : "Yes"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default DeleteModuleModal;
