import { Fragment } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  plantName,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_API_URL } from "../../../../api";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";
import { dateNow } from "../../../functions/utilities";


  



const AddSnapshotModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  stringID,
  setRefetch,
}) => {
  
  const [timestamp, setTimestamp] = useState("");
  const [averageIrradiance,setAverageIrradiance] = useState("");
  const [convertedPower, setConvertedPower] = useState("");
  let currentDate = dateNow();
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async () => {
    setIsLoading(true);
    let finalObj = {
      timestamp: timestamp || currentDate, 
      average_irradiance: averageIrradiance,
      converted_power: convertedPower,
      string_id: id

    }


    console.log(finalObj);
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.post(
        `${BASE_API_URL}/string/performance`,
        finalObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data, "data");
      if (data.status === true) {
        setShowModal(!showModal);
        setRefetch(true);
        setIsLoading(false);
        toast.success("Snapshot added successfully!");
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
        <ModalHeader toggle={Modaltoggle}>{"Add Snapshot "}</ModalHeader>
        <ModalBody>
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              //   onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{"Date and Time"}</Label>
                  <input
                    className="form-control"
                    name="timestamp"
                    type="datetime-local"
                    placeholder="Date and Time"
                    value={currentDate || timestamp}
                    onChange={(e) =>setTimestamp(moment(e.target.value).toISOString())}
                    // {...register("plantName", { required: true })} 
                  />
                  {/* <span>{errors.plantName && "Plant name is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div> */}
                </Col>
              </Row>

              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{"String ID"}</Label>
                  <input
                    className="form-control"
                    name="stringID"
                    type="text"
                    placeholder="String ID"
                    value={stringID}
                    // onChange={(e) =>setSnapshotObj({...snapshotObj, string_id:stringID})}
                    // {...register("plantName", { required: true })}
                  />
                  {/* <span>{errors.plantName && "Plant name is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div> */}
                </Col>
              </Row>

              <Row>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Average Irradiance"}
                  </Label>
                  <input
                    className="form-control"
                    name="averageIrradiance"
                    type="text"
                    placeholder="Average Irradiance"
                    onChange={(e) => setAverageIrradiance(e.target.value)
                    }
                    // {...register("location", { required: true })}
                  />
                  {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                </Col>

                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom04">
                    {"Temperature Corrected Power"}
                  </Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="convertedPower"
                    type="text"
                    placeholder="Temperature Corrected Power"
                    onChange={(e) => setConvertedPower(e.target.value)
                    }
                    // {...register("addressName", { required: true })}
                  />
                  {/* <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div> */}
                </Col>
              </Row>

              <FormGroup>
                <div className="form-check">
                  {/* <div className="checkbox p-0">
                    <Input
                      className="form-check-input"
                      id="invalidCheck"
                      type="checkbox"
                    />
                    <Label className="form-check-label" htmlFor="invalidCheck">
                      {"Agree to terms and conditions"}
                    </Label>
                  </div> */}
                  {/* <div className="invalid-feedback">
                    {"You must agree before submitting."}
                  </div> */}
                </div>
              </FormGroup>
              {/* <Button color="primary">{"Submit form"}</Button> */}
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={Modaltoggle}>
            {Close}
          </Button>
          <Button
            style={isLoading ? { cursor: "not-allowed" } : {}}
            className={isLoading && `disabled`}
            color="primary"
            onClick={handleSubmit}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddSnapshotModal;
