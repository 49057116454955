import React, { Fragment } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  Edit,
  Video,
  Image,
  Activity,
  Tool,
  Truck,
  Table,
} from "react-feather";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionBody,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ImageCarousel from "../../application/Solar-app/ImageCarousel";
import { dateFormat } from "../../application/Solar-app/modules/ModulesDetails";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { GiFactory } from "react-icons/gi";
import { MdViewModule } from "react-icons/md";
import axios from "axios";
import { BASE_API_URL, getPlantDetails } from "../../../api";
import { toast } from "react-toastify";

const Timeline1 = () => {
  const moduleTraceData = useSelector(
    (state) => state.auth.traceData?.data?.data
  );
  const token = localStorage.getItem("token");

  let mongooseModuleId = moduleTraceData?._id;

  const [data, setData] = useState([]);
  const [tests, setTests] = useState([]);

  const getAllEventsFromTrace = async () => {
    if (moduleTraceData) {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/event?module_id=${mongooseModuleId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(data.data);
      } catch (error) {
        toast.error("Something Went Wrong!");
      }
    }
  };

  const getAllTests = async () => {
    if (moduleTraceData) {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/test?module_id=${mongooseModuleId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTests(data.data);
      } catch (error) {
        toast.error("Something went wrong!");
      }
    }
  };

  const [sorted, setSorted] = useState([]);

  const sortTheArray = () => {
    if (moduleTraceData) {
      let mergedArray = [...data, ...tests];

      let sortedArray = mergedArray?.sort((a, b) =>
        a?.timestamp?.localeCompare(b.timestamp)
      );
      setSorted(sortedArray);
    }
  };

  console.log(sorted, "sorted array of events");

  // plant details

  useEffect(() => {
    sortTheArray();
    getAllEventsFromTrace();
    getAllTests();
  }, [moduleTraceData]);

  return (
    <Fragment>
      {sorted ? (
        <VerticalTimeline>
          {sorted?.map((el) => {
            return (
              <>
                {el?.name === "PRODUCTION" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    animate={true}
                    date={dateFormat(el?.timestamp)}
                    icon={<GiFactory />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>
                    <p>
                      {`Manufactured by: ${el?.parameters?.manifacturedBy}`}
                    </p>
                    <p>{`Production ID: ${el?.parameters?.productionOrderId}`}</p>
                    <p>{`Produced At: ${el?.parameters?.producedAt.raw_address}`}</p>
                    <hr></hr>

                    {el?.tests !== [] ? (
                      <>
                        <h6>{`PRODUCTION TEST DETAILS`}</h6>
                        {el?.tests.map((test) => {
                          return (
                            <div>
                              <span>{`${test?.type} Test`}</span>{" "}
                              <span>{` | `}</span>
                              <span>{`${dateFormat(test?.date)}`}</span>
                              <span>{` | `}</span>
                              <span>
                                <Link to={`/tests/${test?._id}`}>
                                  View Details
                                </Link>
                              </span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.name === "SALE" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    icon={<BiPurchaseTagAlt />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>
                    <p>{`Buyer Details: ${el?.parameters?.buyerName}`}</p>
                    <p>{`Seller Details: ${el?.parameters?.sellerName}`}</p>
                    <hr></hr>

                    {el?.tests !== [] ? (
                      <>
                        <h6>{`SALE TEST DETAILS`}</h6>
                        {el?.tests.map((test) => {
                          return (
                            <div>
                              <span>{`${test?.type} Test`}</span>{" "}
                              <span>{` | `}</span>
                              <span>{`${dateFormat(test?.date)}`}</span>
                              <span>{` | `}</span>
                              <span>
                                <Link to={`/tests/${test?._id}`}>
                                  View Details
                                </Link>
                              </span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.name === "TRANSPORT" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<Truck />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>

                    <p>
                      {`Source Details: ${el?.parameters?.sourceAddress?.raw_address}`}
                    </p>
                    <p>
                      {`Destination Details: ${el?.parameters?.destinationAddress?.raw_address}`}
                    </p>
                  </VerticalTimelineElement>
                )}

                {el?.name === "INSTALLATION" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>

                    {/* <p>{`User Name: ${el?.parameters?.userId}`}</p> */}
                    {/* <p>{`Plant Name: ${el?.parameters?.address?.}`}</p> */}
                    <p>
                      {`Plant ID: `}
                      <Link to={`/plant/${el?.parameters?.plantId}`}>
                        {el?.parameters?.plantId}
                      </Link>
                    </p>
                    <hr></hr>

                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.name === "WARRANTY_CLAIM" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>

                    {/* <p>{`User Name: ${el?.parameters?.userId}`}</p>
                    <p>{`Plant Name: ${el?.parameters?.address?.raw_address}`}</p>
                    <p>{`Plant ID: ${el?.parameters?.plantId}`}</p>
                    <hr></hr> */}
                    {/* {el?.tests !== [] ? (
                      <>
                        <h6>{`INSTALLATION TEST DETAILS`}</h6>
                        {el?.tests.map((test) => {
                          return (
                            <div>
                              <span>{`${test?.type} Test`}</span>{" "}
                              <span>{` | `}</span>
                              <span>{`${dateFormat(test?.date)}`}</span>
                              <span>{` | `}</span>
                              <span>
                                <Link to={`/tests/${test?._id}`}>
                                  View Details
                                </Link>
                              </span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div></div>
                    )} */}
                    {/* {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )} */}
                  </VerticalTimelineElement>
                )}

                {el?.name === "STRINGFLAG" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<Activity />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>

                    {/* <p>{`User Name: ${el?.parameters?.userId}`}</p> */}
                    <p>{`Module Efficiency: ${el?.module?.efficiency}`}</p>
                    <p>
                      {" "}
                      {`String ID: `}
                      <Link to={`/string/${el?.parameters?.string_id}`}>
                        {el?.parameters?.string_id}
                      </Link>
                    </p>
                    <hr></hr>
                    {el?.tests === [] ? (
                      <div>No Tests Found</div>
                    ) : (
                      <>
                        <h6>Test Details</h6>
                        {el?.tests.map((test) => {
                          return (
                            <div>
                              <span>{`${test?.type} Test`}</span>{" "}
                              <span>{` | `}</span>
                              <span>{`${dateFormat(test?.date)}`}</span>
                              <span>{` | `}</span>
                              <span>
                                <Link to={`/tests/${test?._id}`}>
                                  View Details
                                </Link>
                              </span>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.name === "MAPPING" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<Activity />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>
                    <p>
                      {" "}
                      {`String ID: `}
                      <Link to={`/string/${el?.parameters?.string_id}`}>
                        {el?.parameters?.string_id}
                      </Link>
                    </p>
                    <hr></hr>
                    {el?.tests === [] ? (
                      <div>No Tests Found</div>
                    ) : (
                      <>
                        <h6>Test Details</h6>
                        {el?.tests.map((test) => {
                          return (
                            <div>
                              <span>{`${test?.type} Test`}</span>{" "}
                              <span>{` | `}</span>
                              <span>{`${dateFormat(test?.date)}`}</span>
                              <span>{` | `}</span>
                              <span>
                                <Link to={`/tests/${test?._id}`}>
                                  View Details
                                </Link>
                              </span>
                            </div>
                          );
                        })}
                      </>
                    )}

                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.name === "REGISTRATION" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={dateFormat(el?.timestamp)}
                    animate={true}
                    icon={<Activity />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">
                      {el?.name}
                    </h4>
                  </VerticalTimelineElement>
                )}

                {el?.type === "FLASH" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={dateFormat(el?.date)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">{` ${el?.type} Test`}</h4>
                    <p>
                      <Link to={`/tests/${el?._id}`}> View Details</Link>
                    </p>
                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}
                {el?.type === "THERMOGRAPHY" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={dateFormat(el?.date)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">{`${el?.type} Test`}</h4>
                    <p>
                      <Link to={`/tests/${el?._id}`}> View Details</Link>
                    </p>
                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}

                {el?.type === "EL" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={dateFormat(el?.date)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">{`${el?.type} Test`}</h4>
                    <p>
                      <Link to={`/tests/${el?._id}`}> View Details</Link>
                    </p>
                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}
                {el?.type === "IV-CURVE" && (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date={dateFormat(el?.date)}
                    animate={true}
                    icon={<MdViewModule />}
                  >
                    <h4 className="vertical-timeline-element-subtitle">{` ${el?.type} Test`}</h4>
                    <p>
                      <Link to={`/tests/${el?._id}`}> View Details</Link>
                    </p>
                    {el?.attachments?.length > 0 ? (
                      <ImageCarousel attachments={el?.attachments} />
                    ) : (
                      <div></div>
                    )}
                  </VerticalTimelineElement>
                )}
              </>
            );
          })}

          {/* <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            date="Feb 26"
                            animate={true}
                            icon={<Edit />}>
                            <h4 className="vertical-timeline-element-subtitle">{"Final Section"}</h4>
                            <p>
                                {"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident"}
                                {"rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus"}
                                {"veritatis qui ut."}
                            </p>
                        </VerticalTimelineElement> */}
        </VerticalTimeline>
      ) : (
        <div>
          <Col md="12">
            <div className="loader-box">
              <div className="loader-35"></div>
            </div>
          </Col>
        </div>
      )}
    </Fragment>
  );
};

export default Timeline1;
