import React, { useState } from "react";
import { Plus } from "react-feather";
import Plantchart from "./plant/PlantChart";
import PlantTable from "./plant/PlantTable";
import AddPlantModal from "./plant/AddPlantModal";

function Plant() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  return (
    <div>
      <Plantchart />
      <div className="container">
        <div className="d-flex justify-content-between mb-3 mt-3 align-items-center">
          <h5>Plant Table </h5>
          <button
            onClick={Modaltoggle}
            style={{ border: "none", borderRadius: "5px" }}
            className="badge-light-primary btn-mail d-flex p-2 align-items-center"
          >
            <Plus className="me-2" />
            Add Plant
          </button>
          <AddPlantModal
            showModal={showModal}
            setShowModal={setShowModal}
            Modaltoggle={Modaltoggle}
            setRefetch={setRefetch}
          />
        </div>
        {/* <PlantTable showModal={showModal} Modaltoggle={Modaltoggle} /> */}
      </div>
      <PlantTable setRefetch={setRefetch} refetch={refetch} />
    </div>
  );
}

export default Plant;
