import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import Chart from "react-apexcharts";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useSelector } from "react-redux";

import dummyBlockPerfomance from "../../../../api/mock-data/block-data.json";
import dummyInverterPerfomance from "../../../../api/mock-data/inverter-data.json";
import { primary, secondary } from "../string/StringChartData";

const Blockchart = () => {
  const [blockPerformanceData, setBlockPerformanceData] = useState([]);
  const [inverterPerformanceData, setInverterPerformanceData] = useState({});

  const blockId = useSelector((state) => state?.Block?.blockId);

  const loadBlockPerformanceData = async (blockId) => {
    let data = dummyBlockPerfomance?.filter(
      (blockData) => blockData.block_id === blockId
    );
    setBlockPerformanceData(data);
  };

  const loadInverterPerformanceData = async (blockId) => {
    let data = dummyInverterPerfomance?.filter(
      (inverter) => inverter.block_id === blockId
    );
    data = data.reduce((acc, current) => {
      acc[[current?.name]] = acc[[current?.name]]
        ? acc[[current?.name]].concat(current)
        : [];
      return acc;
    }, {});
    setInverterPerformanceData(data);
  };

  useEffect(() => {
    loadBlockPerformanceData(blockId);
    loadInverterPerformanceData(blockId);
  }, [blockId]);

  return (
    <Fragment>
      <Breadcrumb title="Block" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>Block Performance </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      yaxis: {
                        title: {
                          text: "Specific Yield (KWh)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        title: {
                          text: "Time",
                        },
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          blockPerformanceData?.[0]?.date
                        ).getTime(),
                      },
                    }}
                    series={[
                      {
                        name: "Block Performance",
                        data: blockPerformanceData.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>Inverter Performance </h5>
              </CardHeader>
              <CardBody>
                <Chart
                  options={{
                    colors: [primary, secondary],
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.5,
                        stops: [0, 80, 100],
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    grid: {
                      show: false,
                    },
                    yaxis: {
                      title: {
                        text: "Power (KWh)",
                      },
                      decimalsInFloat: 3,
                    },
                    xaxis: {
                      title: {
                        text: "Time",
                      },
                      type: "datetime",
                      tickAmount: 1,
                      min: new Date(
                        inverterPerformanceData?.[0]?.date
                      ).getTime(),
                    },
                  }}
                  series={[
                    ...Object.values(inverterPerformanceData).map(
                      (inverter, i) => {
                        return {
                          name: `${Object.keys(inverterPerformanceData)[i]}`,
                          data: inverter.map((item) => ({
                            y: item?.converted_power,
                            x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                          })),
                        };
                      }
                    ),
                  ]}
                  height="350"
                  type="area"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Blockchart;
