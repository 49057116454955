import {SET_ACTIVE_INVERTER} from '../actionTypes';

const initial_state = {
  inverterId: null,
};

const blockReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACTIVE_INVERTER:
      return {...state, inverterId: action.payload.inverterId};

    default:
      return {...state};
  }
};
export default blockReducer;
