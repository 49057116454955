import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Edit } from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Table } from "reactstrap";
import { BASE_API_URL, getModulesDetails } from "../../../../api";
import Breadcrumbs from "../../../../layout/breadcrumb";
import { getTraceData } from "../../../../redux/auth/action";
import AddTestModal from "./AddTestModal";
import WarrantyClaimModal from "./WarrantyClaimModal";

export const dateFormat = (isoString) => {
  const date = new Date(isoString);
  const formatteddate =
    date.toLocaleDateString() + " " + date.toLocaleTimeString();
  return formatteddate;
};

const ModulesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [moduleTestsList, setModuleTestsList] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const moduleDetails = useSelector(
    (state) => state.auth.traceData?.data?.data
  );

  const getModuleDetails = async () => {
    if (id) {
      dispatch(getTraceData(id));
    }
  };

  const getModuleTests = async (id) => {
    const token = localStorage.getItem("token");

    const res = await axios.get(`${BASE_API_URL}/test?module_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setModuleTestsList(res.data.data);
  };

  useEffect(() => {
    getModuleDetails();
    getModuleTests(id);
  }, [id]);

  useEffect(() => {
    if (refetch) {
      getModuleTests(id);
    }
  }, [refetch, id]);

  const [showClaimModal, setShowClaimModal] = useState(false);
  const ClaimModaltoggle = () => setShowClaimModal(!showClaimModal);

  const [showModal, setShowModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  return (
    <Fragment>
      <Breadcrumbs title="Modules Details" />
      <Container fluid={true}>
        {/* <Row>
            <Col sm="12" xl="12">
              <Card>
                <CardHeader>
                  <h5>{"String Performance"} </h5>
                </CardHeader>
                <CardBody>
                  <div id="basic-apex">
                    <Chart
                      options={apexAreaChart.options}
                      series={apexAreaChart.series}
                      type="area"
                      height={350}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Row sm="12">
                      <Col sm="9" className="media-body align-self-center">
                        <h1 className="mt-0 user-name">{"Module Details"}</h1>
                      </Col>
                      <Col sm="3">
                        <Button color="primary" onClick={ClaimModaltoggle}>
                          File Warranty Claim
                        </Button>
                        <WarrantyClaimModal
                          showClaimModal={showClaimModal}
                          ClaimModaltoggle={ClaimModaltoggle}
                          moduleId={id}
                          setShowClaimModal={setShowClaimModal}
                        />
                      </Col>
                    </Row>
                    {/* <Col sm="4" className="align-self-center">
                          <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                        </Col> */}
                  </Row>

                  <hr />

                  <Row>
                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>Module ID</h6>
                        <span>{moduleDetails?.id}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Serial Number</h6>
                        <span>{moduleDetails?.serial_number}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Efficiency</h6>
                        <span>{moduleDetails?.efficiency}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start">
                        <h6>String Name</h6>
                        <span>
                          <Link to={`/string/${moduleDetails?.string?._id}`}>
                            {moduleDetails?.string?.name}
                          </Link>
                        </span>
                      </div>
                    </Col>
                  </Row>

                  {/* 
                      <div className="img-container">
                        <div id="aniimated-thumbnials">
                          <a href="#javascript">
                            <Media className="img-fluid rounded" />
                          </a>
                        </div>
                      </div> */}
                  {/* <div className="like-comment mt-4">
                        <ul className="list-inline"> 
                          <li className="list-inline-item border-end pe-3">
                          <div>
              <Button color="primary" type="submit">
                {"Add Tests"}
              </Button>
            </div>
                          </li>

                        </ul>
                      </div> */}
                </div>
              </Card>
            </Col>
            <Row>
              <Col xl="6">
                <Card>
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="9" className="media-body align-evenly">
                          <h5 className="mt-0 user-name">
                            {"Module Event list"}
                          </h5>
                        </Col>
                        {/* <Col sm="3">
                          <Button color="primary">Add T</Button>
                        </Col> */}
                      </Row>

                      <div className="table-responsive">
                        <Table borderless>
                          <thead>
                            <tr>
                              <th scope="col">{"#"}</th>
                              <th scope="col">{"Event Name"}</th>
                              <th scope="col">{"Timestamp"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {moduleDetails?.events?.map((event, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{event?.name}</td>
                                  <td>{dateFormat(event?.timestamp)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                    <hr />
                  </div>
                </Card>
              </Col>

              <Col xl="6">
                <Card>
                  <div className="profile-img-style">
                    <Row>
                      <Row sm="12">
                        <Col sm="8" className="media-body align-self-center">
                          <h5 className="mt-0 user-name">
                            {"Module Test list"}
                          </h5>
                        </Col>
                        <Col sm="4">
                          <Button color="primary" onClick={Modaltoggle}>
                            Add Test
                          </Button>
                        </Col>
                        <AddTestModal
                          showModal={showModal}
                          setShowModal={setShowModal}
                          Modaltoggle={Modaltoggle}
                          setRefetch={setRefetch}
                        />
                      </Row>

                      <div className="table-responsive">
                        <Table borderless>
                          <thead>
                            <tr>
                              <th scope="col">{"#"}</th>
                              <th scope="col">{"Test Name"}</th>
                              <th scope="col">{"Timestamp"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {moduleTestsList?.map((test, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    <Link
                                      to={`/tests/${test?._id}`}
                                    >{`${test?.type} Test`}</Link>
                                  </td>
                                  <td>{dateFormat(test?.date)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                    <hr />
                  </div>
                </Card>
              </Col>
            </Row>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default ModulesDetails;
