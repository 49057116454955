import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Edit } from "react-feather";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Media,
  Table,
  CardBody,
} from "reactstrap";
import { getInverterDetails } from "../../../../api";
import Chart from "react-apexcharts";

import dummyInverterPerfomance from "../../../../api/mock-data/inverter-data.json";
import { secondary } from "../string/StringChartData";
import Breadcrumbs from "../../../../layout/breadcrumb";

const InverterDetails = () => {
  const { id } = useParams();

  const [inverterDetails, setInverterDetails] = useState([]);
  const [stringsList, setStringsList] = useState([]);
  const [inverterPerformanceData, setInverterPerformanceData] = useState([]);

  const loadInverterPerformanceData = async (inverterId) => {
    let data = dummyInverterPerfomance?.filter(
      (inverterData) => inverterData.inverter_id === inverterId
    );

    setInverterPerformanceData(data);
  };

  const getData = async () => {
    const { data } = await getInverterDetails(id);
    setInverterDetails(data.data);
    setStringsList(data.data.strings);
    loadInverterPerformanceData(id);
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);
  return (
    <Fragment>
      <Breadcrumbs title="Inverter Details" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="12">
            <Card>
              <CardHeader>
                <h5>{"Inverter Performance"} </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      yaxis: {
                        title: {
                          text: "Power (MWh)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        title: {
                          text: "Time",
                        },
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          inverterPerformanceData?.[0]?.date
                        ).getTime(),
                      },
                    }}
                    series={[
                      {
                        name: "Plant Performance",
                        data: inverterPerformanceData.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media-body align-self-center">
                        <h1 className="mt-0 user-name">{"Inverter Details"}</h1>
                      </div>
                    </Col>
                    {/* <Col sm="4" className="align-self-center">
                          <div className="float-sm-end"><small>{"10 Hours ago"}</small></div>
                        </Col> */}
                  </Row>

                  <hr />

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start">
                        <h6>Inverter ID</h6>
                        <span>{inverterDetails?._id}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Inverter Serial Number</h6>
                        <span>{inverterDetails?.serial_number}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Model Number</h6>
                        <span>{inverterDetails?.model_number}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Block ID</h6>
                        <span>{inverterDetails?.block_id}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>Raw Address</h6>
                        <span>{inverterDetails?.address?.raw_address}</span>
                      </div>
                    </Col>
                  </Row>
                  {/* 
                      <div className="img-container">
                        <div id="aniimated-thumbnials">
                          <a href="#javascript">
                            <Media className="img-fluid rounded" />
                          </a>
                        </div>
                      </div> */}
                  {/* <div className="like-comment mt-4">
                        <ul className="list-inline"> 
                          <li className="list-inline-item border-end pe-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ms-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ms-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ms-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div> */}
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="12">
                      <div className="media-body align-self-center">
                        <h5 className="mt-0 user-name">{"Modules list"}</h5>
                      </div>
                    </Col>
                    {/* modules in the given string */}
                    <div className="table-responsive">
                      <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">{"#"}</th>
                            <th scope="col">{"String ID"}</th>
                            <th scope="col">{"String Name"}</th>
                            {/* <th scope="col">{"Efficiency"}</th> */}
                            <th scope="col">{"Actions"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stringsList?.map((string, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <Link to={`/string/${string?._id}`}>
                                    {string?._id}
                                  </Link>
                                </td>
                                <td>{string?.name}</td>
                                {/* <td>{module?.efficiency}</td> */}
                                <td>
                                  <div>
                                    <Edit />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <hr />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default InverterDetails;
