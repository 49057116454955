import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import Chart from "react-apexcharts";
import { primary, secondary } from "./StringChartData";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useParams } from "react-router";
import { getStringListData } from "../../../../api";

const Stringchart = () => {
  const { id } = useParams();

  const [stringListData, setStringListData] = useState([]);
  const [pieData, setPieData] = useState({});

  const getData = async () => {
    const data = await getStringListData();
    const pieData = data.reduce((acc, cur) => {
      acc[[cur?.status]] = (acc[[cur?.status]] || 0) + 1;
      return acc;
    }, {});

    setStringListData(data);
    setPieData(pieData);
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumb link="/string" title="String" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>String Health</h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [primary, secondary],
                      labels: Object.keys(pieData),
                    }}
                    series={Object.values(pieData)}
                    type="donut"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>String Power</h5>
              </CardHeader>
              <CardBody>
                <Chart
                  options={{
                    colors: [secondary, primary],
                    labels: stringListData.map((string) => string?.name),
                    dataLabels: {
                      enabled: false,
                    },
                    yaxis: {
                      title: {
                        text: "Power(KW)",
                      },
                      decimalsInFloat: 1,
                    },
                  }}
                  series={[
                    {
                      data: stringListData.map((string) => string?.max_power),
                    },
                  ]}
                  height="350"
                  type="bar"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Stringchart;
