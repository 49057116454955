import React, { Fragment, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
// import { getPlantListData } from "../../../../api";
// import { SET_ACTIVE_PLANT } from "../../../../redux/actionTypes";
// import { primary } from "../string/StringChartData";
// import AddPlantModal from "./AddPlantModal";
// // import { acivePlantDispatcher } from "../../../../redux/plant/action";
// import DeletePlantModal from "../plant/deleteModal";
// import EditPlantModal from "./EditPlantModal";
import Breadcrumbs from "../layout/breadcrumb";
import { MdViewModule } from "react-icons/md";

const Recycler = ({ refetch, setRefetch }) => {
  const [plantList, setPlantList] = useState([]);
  const [activePlant, setAcivePlant] = useState(null);
  const [activePlantIndex, setAcivePlantIndex] = useState(0);
  const [selectedPlantId, setSelectedPlantId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  const deletetoggle = () => setShowDeleteModal(!showDeleteModal);

  const dispatch = useDispatch();

  //   const getData = async () => {
  //     const { data } = await getPlantListData();
  //     setPlantList(data.data);
  //     setAcivePlant(data.data[0]);
  //     dispatch(acivePlantDispatcher(data?.data[0]?._id));
  //   };

  const handleActivePlant = (index, plant) => {
    setAcivePlant(plant);
    setAcivePlantIndex(index);
    // dispatch({
    //   type: SET_ACTIVE_PLANT,
    //   payload: {plantId: plant?._id},
    // });
    // dispatch(acivePlantDispatcher(plant?._id));
  };

  //   useEffect(() => {
  //     getData();
  //   }, []);

  //   useEffect(() => {
  //     if (refetch) {
  //       getData();
  //     }
  //   }, [refetch]);

  const recycleData = [
    {
      id: 1,
      serial_number: "AS1709063B0680",
      status: "Reusable",
      color: "green",
      textColor: "#fff",
    },
    {
      id: 2,
      serial_number: "AS106534B0680",
      status: "Recyclable",
      color: "orange",
      textColor: "#fff",
    },
    {
      id: 3,
      serial_number: "AS106534B0650",
      status: "Disposable",
      color: "red",
      textColor: "#fff",
    },
    {
      id: 4,
      serial_number: "AS1709063B0680",
      status: "Reusable",
      color: "green",
      textColor: "#fff",
    },
    {
      id: 5,
      serial_number: "AS106534B0680",
      status: "Recyclable",
      color: "orange",
      textColor: "#fff",
    },
    {
      id: 6,
      serial_number: "AS106534B0650",
      status: "Disposable",
      color: "red",
      textColor: "#fff",
    },
    {
      id: 5,
      serial_number: "AS106534B0680",
      status: "Recyclable",
      color: "orange",
      textColor: "#fff",
    },
    {
      id: 4,
      serial_number: "AS1709063B0680",
      status: "Reusable",
      color: "green",
      textColor: "#fff",
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs link="/recycler" title="Recycle Modules" />
      <Container fluid={true}>
        <Row>
          <Col xl="9 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <MdViewModule size={50} color="#7366ff" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>1000</h4>
                          <span>{"Total Modules"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <MdViewModule size={50} color="orange" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>600</h4>
                          <span>{"Modules that are Recyclable"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <MdViewModule size={50} color="green" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>300</h4>
                          <span>{"Reuseable Modules"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <MdViewModule size={50} color="crimson" />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>100</h4>
                          <span>{"Disposable Modules"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"Module ID"}</th>
                      <th scope="col">{"Module Serial Number"}</th>
                      <th scope="col">{"Status"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recycleData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{data?.serial_number}</td>
                          <td>
                            <button
                              style={{
                                backgroundColor: `${data?.color}`,
                                color: `${data?.textColor}`,
                              }}
                              className="btn primary"
                            >
                              {data?.status}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Recycler;
