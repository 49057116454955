import React, { Fragment, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
// import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table } from "reactstrap";
import { getBlockListData } from "../../../../api";
import { primary } from "../string/StringChartData";
import AddBlockModal from "./AddBlockModal";

import { aciveBlockDispatcher } from "../../../../redux/block/action";
import { useDispatch } from "react-redux";

const BlockTable = ({ showModal, Modaltoggle, refetch }) => {
  const [blockList, setBlockList] = useState([]);
  const [activeBlock, setAciveBlock] = useState(null);
  const [activeBlockIndex, setAciveBlockIndex] = useState(0);

  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await getBlockListData();
    setBlockList(data.data);
    dispatch(aciveBlockDispatcher(data?.data?.[0]?._id));
  };

  const handleActiveBlock = (index, block) => {
    setAciveBlock(block);
    setAciveBlockIndex(index);
    dispatch(aciveBlockDispatcher(block?._id));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  return (
    <Fragment>
      {/* <Breadcrumb parent="Tables" title="Basic Tables" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Block ID"}</th>
                      <th scope="col">{"Block"}</th>
                      <th scope="col">{"Plant"}</th>
                      <th scope="col">{"Block Address"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blockList?.map((block, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => handleActiveBlock(index, block)}
                          style={
                            activeBlockIndex === index
                              ? { boxShadow: `-2px 1px 10px ${primary}` }
                              : {}
                          }
                        >
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link to={`/block/${block?._id}`}>
                              {block?._id}
                            </Link>
                          </td>
                          <td>{block?.name}</td>
                          <td>
                            {" "}
                            <Link to={`/plant/${block?.plant_id}`}>
                              {block?.plant?.name}
                            </Link>
                          </td>
                          <td>{block?.address?.raw_address}</td>
                          <td>
                            <div onClick={Modaltoggle}>
                              <Edit color="#7366ff" />
                              &nbsp;
                              {/* <Trash2 color="crimson" /> */}
                            </div>
                          </td>
                          {/* <AddBlockModal
                            showModal={showModal}
                            Modaltoggle={Modaltoggle}
                          /> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BlockTable;
