import { Fragment, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  plantName,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import jwtDecode from "jwt-decode";
import { createPlant } from "../../../../api";
import { toast } from "react-toastify";

export const AddPlantModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let data = new FormData(e.target);

      var formData = {};
      data.forEach((value, key) => {
        formData[key] = value;
      });

      const userToken = localStorage.getItem("token");
      let decodedUser = jwtDecode(userToken);

      const payload = {
        name: formData.plantName,
        user_id: decodedUser.id,
        isActive: true,
        address: {
          location: {
            type: "Point",
            coordinates: [],
          },
          name: formData.addressName,
          local_area: formData.localArea,
          village: formData.village,
          taluka: formData.taluka,
          district: formData.district,
          state: formData.state,
          country: formData.country,
          pincode: formData.zipCode,
          raw_address: `${formData.localArea}, ${formData.village}, ${formData.taluka}, ${formData.district}, ${formData.state}, ${formData.country}, ${formData.zipCode}`,
        },
      };

      const plant = await createPlant(payload);
      setRefetch(true);
      toast.success("Plant added successfully!");
      setIsLoading(false);
      e.target.reset();
      setShowModal(false);
    } catch (error) {
      toast.error(`${error.message}`);
      console.log(error);
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit}
        >
          <ModalHeader toggle={Modaltoggle}>{"Plant Details"}</ModalHeader>
          <ModalBody>
            <CardBody>
              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{plantName}</Label>
                  <input
                    className="form-control"
                    name="plantName"
                    type="text"
                    placeholder="Plant Name"
                    {...register("plantName", { required: true })}
                  />
                  <span>{errors.plantName && "Plant name is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div>
                </Col>
              </Row>

              <Row>
                <h5>Plant Address Details</h5>
                {/* location coordinates */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{location}</Label>
                  <input
                    className="form-control"
                    name="location"
                    type="text"
                    placeholder="Location"
                    {...register("location", { required: true })}
                  />
                  <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div>
                </Col>
                {/* address Name */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom04">{addressName}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="addressName"
                    type="text"
                    placeholder="Address Name"
                    {...register("addressName", { required: true })}
                  />
                  <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div>
                </Col>
                {/* local area */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom05">{localArea}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="localArea"
                    type="text"
                    placeholder="Local Area"
                    {...register("localArea", { required: true })}
                  />
                  <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div>
                </Col>
              </Row>
              <Row>
                {/* village */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{village}</Label>
                  <input
                    className="form-control"
                    name="village"
                    type="text"
                    placeholder="Village"
                    {...register("village", { required: true })}
                  />
                  <span>
                    {errors.location && "Please provide a Village Details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Village Details"}
                  </div>
                </Col>
                {/* taluka */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{taluka}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="taluka"
                    type="text"
                    placeholder="Taluka"
                    {...register("taluka", { required: true })}
                  />
                  <span>
                    {errors.addressName && "Please Provide taluka details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide taluka details"}
                  </div>
                </Col>
                {/* district*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{district}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="district"
                    type="text"
                    placeholder="District"
                    {...register("district", { required: true })}
                  />
                  <span>
                    {errors.district && "Please provide a District Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a District Name."}
                  </div>
                </Col>
              </Row>
              <Row>
                {/* State */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{state}</Label>
                  <input
                    className="form-control"
                    name="state"
                    type="text"
                    placeholder="State"
                    {...register("state", { required: true })}
                  />
                  <span>
                    {errors.State && "Please provide a State Details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a State Details"}
                  </div>
                </Col>
                {/* Country */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{country}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="country"
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                  />
                  <span>
                    {errors.country && "Please Provide a Country name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a country name"}
                  </div>
                </Col>
                {/* pincode/zipcode*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{zipCode}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="zipCode"
                    type="text"
                    placeholder="Zip Code"
                    {...register("zipCode", { required: true })}
                  />
                  <span>{errors.zipCode && "Please provide a zip code"}</span>
                  <div className="invalid-feedback">
                    {"Please provide a zipcode/Pincode."}
                  </div>
                </Col>
              </Row>
              <FormGroup>
                <div className="form-check"></div>
              </FormGroup>
              {/* <Button color="primary">{"Submit form"}</Button> */}
            </CardBody>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={Modaltoggle}>
              {Close}
            </Button>
            <Button
              style={isLoading ? { cursor: "not-allowed" } : {}}
              className={isLoading && `disabled`}
              color="primary"
              type="submit"
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AddPlantModal;
