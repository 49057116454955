import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useParams } from "react-router";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { Buffer } from "buffer";
import {
  Button,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { uploadReports, uploadTests } from "../../../../api";
import moment from "moment";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { toast } from "react-toastify";

const AddReportModal = ({
  showModal,
  Modaltoggle,
  setShowModal,
  testDetails,
  setRefetch,
}) => {
  const [testType, setTestType] = useState("");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [finalAttachments, setFinalAttachments] = useState([]);
  const [file, setFile] = useState(null);
  const [finalResult, setFinalResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");

  const authParam = `${"2FwEAiZi3XEzu3SbttRO3IxbExl"}:${"4cf7f0d1feadbabb27f3360c7bb7e951"}`;

  const { id } = useParams();

  console.log(id, "testType");

  const uploadReportData = async () => {
    setIsLoading(true);
    toast.info("Uploading report");
    let attachmentUrl = await uploadToIpfs();
    console.log(attachmentUrl);
    let finalObj = {
      test_id: id,
      module_id: testDetails.module_id,
      attachment: {
        type: file?.details?.type,
        path: attachmentUrl,
      },

      result: finalResult,
    };
    console.log(finalObj);
    const testResponse = await uploadReports(finalObj);
    if (testResponse) {
      setRefetch(true);
      setIsLoading(false);
      setShowModal(false);
      setFile(null);
      setFinalResult("");
    }
    console.log(testResponse);
    setIsLoading(false);
  };

  const client = ipfsHttpClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: `Basic ${Buffer.from(authParam).toString("base64")}`,
    },
  });

  const handleImgInput = (e) => {
    const file = {
      url: URL.createObjectURL(e.target.files[0]),
      details: e.target.files[0],
    };
    // console.log(file);
    setFile(file);
  };

  const uploadToIpfs = async () => {
    try {
      const added = await client.add(file?.details, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      return added.path;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  console.log(file?.details, "file details");

  return (
    <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
      <ModalHeader>Add Reports</ModalHeader>
      <ModalBody>
        <CardBody>
          <div className="dz-message needsclick">
            {file?.url ? (
              ""
            ) : (
              <div class="dzu-dropzone">
                <label class="dzu-inputLabel">
                  Click to Browse
                  <input
                    onChange={(e) => handleImgInput(e)}
                    className="dzu-input"
                    type="file"
                    accept="image/*,application/pdf"
                  />
                </label>
              </div>
            )}
            {file?.url &&
              (file?.details?.type.match(/image\/*/) !== null ? (
                <>
                  <div className="d-flex mb-3 justify-content-center">
                    <Button onClick={() => setFile(null)} color="secondary">
                      Cancel
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      width="300px"
                      className="img-fluid"
                      src={file?.url}
                      alt="img"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex mb-3 justify-content-center">
                    <Button onClick={() => setFile(null)} color="secondary">
                      Cancel
                    </Button>
                  </div>
                  <div
                    className="custom-scrollbar"
                    style={{ height: "350px", overflowY: "scroll" }}
                  >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        width={"100%"}
                        height={"100%"}
                        fileUrl={`${file?.url}`}
                      />
                    </Worker>
                  </div>
                </>
              ))}
            {file?.url && (
              <div className="d-flex justify-content-center mt-5">
                <Col md="6 mb-3">
                  <Label>{"Add Final Result"}</Label>
                  <textarea
                    className="form-control"
                    name="finalResult"
                    type="text"
                    onChange={(e) => setFinalResult(e.target.value)}
                    value={finalResult}
                    placeholder="Final Result"
                  ></textarea>
                </Col>
              </div>
            )}
          </div>
        </CardBody>
      </ModalBody>
      <ModalFooter>
        <Button
          style={isLoading ? { cursor: "not-allowed" } : {}}
          className={isLoading && `disabled`}
          onClick={() => uploadReportData()}
          color="primary"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
        <Button color="secondary" onClick={Modaltoggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddReportModal;
