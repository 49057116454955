import { useState } from "react";
import { Fragment } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CarouselControl,
  UncontrolledCarousel,
  Media,
} from "reactstrap";

const items1 = [
  {
    src: require("../../../assets/images/Carousel/1.jpg"),
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: require("../../../assets/images/Carousel/2.jpg"),
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    src: require("../../../assets/images/Carousel/3.jpg"),
    altText: "Slide 3",
    caption: "Slide 3",
  },
];

const ImageCarousel = ({ attachments }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items1.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items1.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  };

  console.log(attachments);

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={attachments}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {attachments?.map((item, i) => {
        return (
          <CarouselItem onExiting={() => setAnimating(true)} key={i}>
            <Media
              src={`https://adani-module.infura-ipfs.io/ipfs/${item?.path}`}
              alt={item?.altText}
              className="img-fluid"
            />
            <CarouselCaption
              captionText={item?.caption}
              captionHeader={item?.caption}
            />
          </CarouselItem>
        );
      })}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default ImageCarousel;
