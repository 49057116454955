import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Close,
  location,
  addressName,
  localArea,
  village,
  taluka,
  district,
  state,
  country,
  zipCode,
  Save,
  blockName,
  serialNumber,
  modelNumber,
  blockId,
  manufacturer,
} from "../../../../constant";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { createInverter, getBlockListData } from "../../../../api";

const AddInverterModal = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [blockData, setBlockData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadBlockdata = async () => {
    let data = await getBlockListData();
    setBlockData(data.data.data || []);
  };

  const loadData = async () => {
    loadBlockdata();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const userToken = localStorage.getItem("token");
      let decodedUser = jwtDecode(userToken);

      const {
        manufacturer,
        modelNumber,
        serialNumber,
        blockId,
        addressName,
        localArea,
        village,
        taluka,
        district,
        state,
        country,
        zipCode,
      } = data;

      const payload = {
        serial_number: serialNumber,
        model_number: modelNumber,
        manufacturer: manufacturer,
        block_id: blockId,
        user_id: decodedUser.id,
        address: {
          location: {
            type: "Point",
            coordinates: [],
          },
          name: addressName,
          local_area: localArea,
          village: village,
          taluka: taluka,
          district: district,
          state: state,
          country: country,
          pincode: zipCode,
          raw_address: `${addressName}, ${localArea}, ${village}, ${taluka}, ${district}, ${state}, ${country}, ${zipCode}`,
        },
      };

      console.log(payload);
      const inverter = await createInverter(payload);
      setRefetch(true);
      toast.success("Inverter added successfully!");
      setIsLoading(false);
      setShowModal(false);
      reset();
    } catch (error) {
      console.log(error);
      setShowModal(false);
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const blockListOptions = blockData.map((item, index) => {
    return (
      <option value={item?._id} key={index}>
        {item?.name}
      </option>
    );
  });

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <ModalHeader toggle={Modaltoggle}>{"Inverter Details"}</ModalHeader>
          <ModalBody>
            <CardBody>
              <Row>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{serialNumber}</Label>
                  <input
                    className="form-control"
                    name="SerialNumber"
                    type="text"
                    placeholder="Inverter Serial Number"
                    {...register("serialNumber", { required: true })}
                  />
                  <span>
                    {errors.serialNumber &&
                      "Inverter Serial Number is required"}
                  </span>
                  <div className="valid-feedback">
                    {"Inverter Serial Number is required"}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{modelNumber}</Label>
                  <input
                    className="form-control"
                    name="modelNumber"
                    type="text"
                    placeholder="Model Number"
                    {...register("modelNumber", { required: true })}
                  />
                  <span>
                    {errors.modelNumber && "Model Number is required"}
                  </span>
                  <div className="valid-feedback">
                    {"Model Number is required"}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">Block</Label>
                  <select
                    className="form-control"
                    name="blockId"
                    type="text"
                    {...register("blockId", { required: true })}
                  >
                    <option value={null}>Select Block</option>
                    {blockListOptions}
                  </select>
                  <span>{errors.blockId && "Block Id is required"}</span>
                  <div className="valid-feedback">{"Block Id is required"}</div>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom01">{manufacturer}</Label>
                  <input
                    className="form-control"
                    name="manufacturer"
                    type="text"
                    placeholder="Manufacturer Name"
                    {...register("manufacturer", { required: true })}
                  />
                  <span>
                    {errors.manufacturer && "manufacturer is required"}
                  </span>
                  <div className="valid-feedback">
                    {"manufacturer is required"}
                  </div>
                </Col>
              </Row>

              <Row>
                <h5>Inverter Address Details</h5>
                {/* location coordinates */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{location}</Label>
                  <input
                    className="form-control"
                    name="location"
                    type="text"
                    placeholder="Location"
                    {...register("location", { required: true })}
                  />
                  <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div>
                </Col>
                {/* address Name */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom04">{addressName}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="addressName"
                    type="text"
                    placeholder="Address Name"
                    {...register("addressName", { required: true })}
                  />
                  <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div>
                </Col>
                {/* local area */}
                <Col md="4 mb-3">
                  <Label htmlFor="validationCustom05">{localArea}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="localArea"
                    type="text"
                    placeholder="Local Area"
                    {...register("localArea", { required: true })}
                  />
                  <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div>
                </Col>
              </Row>
              <Row>
                {/* village */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{village}</Label>
                  <input
                    className="form-control"
                    name="village"
                    type="text"
                    placeholder="Village"
                    {...register("village", { required: true })}
                  />
                  <span>
                    {errors.location && "Please provide a Village Details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Village Details"}
                  </div>
                </Col>
                {/* taluka */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{taluka}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="taluka"
                    type="text"
                    placeholder="Taluka"
                    {...register("taluka", { required: true })}
                  />
                  <span>
                    {errors.addressName && "Please Provide taluka details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide taluka details"}
                  </div>
                </Col>
                {/* district*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{district}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="district"
                    type="text"
                    placeholder="District"
                    {...register("district", { required: true })}
                  />
                  <span>
                    {errors.district && "Please provide a District Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a District Name."}
                  </div>
                </Col>
              </Row>
              <Row>
                {/* State */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">{state}</Label>
                  <input
                    className="form-control"
                    name="state"
                    type="text"
                    placeholder="State"
                    {...register("state", { required: true })}
                  />
                  <span>
                    {errors.State && "Please provide a State Details"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a State Details"}
                  </div>
                </Col>
                {/* Country */}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom04">{country}</Label>
                  <input
                    className="form-control"
                    id="validationCustom04"
                    name="country"
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                  />
                  <span>
                    {errors.country && "Please Provide a Country name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a country name"}
                  </div>
                </Col>
                {/* pincode/zipcode*/}
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom05">{zipCode}</Label>
                  <input
                    className="form-control"
                    id="validationCustom05"
                    name="zipCode"
                    type="text"
                    placeholder="Zip Code"
                    {...register("zipCode", { required: true })}
                  />
                  <span>{errors.zipCode && "Please provide a zip code"}</span>
                  <div className="invalid-feedback">
                    {"Please provide a zipcode/Pincode."}
                  </div>
                </Col>
              </Row>
              <FormGroup>
                <div className="form-check"></div>
              </FormGroup>
            </CardBody>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={Modaltoggle}>
              {Close}
            </Button>
            <Button
              style={isLoading ? { cursor: "not-allowed" } : {}}
              className={isLoading && `disabled`}
              color="primary"
              type="submit"
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AddInverterModal;
