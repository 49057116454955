import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ApexCharts from "react-apexcharts";
import Knob from "knob";
import ChartistChart from "react-chartist";
import {
  smallchart1data,
  smallchart1option,
  smallchart2data,
  smallchart2option,
  smallchart3data,
  smallchart3option,
} from "./chartsData/chartist-charts-data";
import { Dashboard, Tomorrow, Yesterday, Today } from "../../constant";
import {
  getAdminDasboardDetails,
  getAlerts,
  getDashboardMetrics,
  getDashboardStringData,
} from "../../api";
import moment from "moment";
import {
  primary,
  secondary,
} from "../application/Solar-app/string/StringChartData";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import Chart from "react-apexcharts";

const AdminDashboard = () => {
  const { user } = useSelector((state) => state?.auth);

  // const [user, setUser] = useState(localStorage.getItem("user") || null);
  const [notifications, setNotifications] = useState([]);
  const [startDate, setStartDate] = useState(new Date("2017-01-01"));
  const [endDate, setEndDate] = useState(new Date());
  const [metrics, setMetrics] = useState({
    modulesCreated: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: "Watts",
    },
    modulesSold: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: "Watts",
    },
    modulesRegistered: { efficiency: 0, count: 0, efficiencyUnit: "Watts" },
    modulesInstalled: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: "Watts",
    },
    warrantyClaims: 0,
    maxPower: {
      converted_power: 0,
      timestamp: "",
      string: {
        name: "",
      },
    },
    minPower: {
      converted_power: 0,
      timestamp: "",
      string: {
        name: "",
      },
    },
  });
  const [groupOn, setGroupOn] = useState("month");
  const [trends, setTrends] = useState([]);
  const [claimData, setClaimData] = useState([]);

  const loadNotificationData = async () => {
    const alerts = await getAlerts(3, 100);
    setNotifications(alerts);
  };

  const loadMetricsData = async () => {
    const metricsData = await getDashboardMetrics({
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
    });
    setMetrics(metricsData);
  };

  const loadAdminData = async () => {
    const { trends, claims } = await getAdminDasboardDetails({
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      groupOn,
    });
    setTrends(trends);
    setClaimData(claims);
  };

  useEffect(() => {
    loadNotificationData();
    loadMetricsData();
    loadAdminData();
  }, []);

  useEffect(() => {
    loadMetricsData();
    loadAdminData();
  }, [groupOn, startDate, endDate]);

  const notificationItems = notifications.map((notification) => {
    return (
      <div className="media" key={notification?._id}>
        <Link
          to={`/${notification?.type?.toLowerCase()}/${
            notification?.reference_id
          }`}
        >
          <div className="media-body">
            <p>
              {moment(notification?.createdAt).format("DD/MM/YYYY")}{" "}
              <span>{moment(notification?.createdAt).format("HH:mm")}</span>
            </p>
            <h6>
              {notification?.message?.toUpperCase()}
              <span className="dot-notification"></span>
            </h6>
            <span>{`Type: ${notification?.type} | ID: ${notification?.reference_id}`}</span>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <Fragment>
      <Breadcrumb link="/dashboard" parent="Dashboard" title="Dashboard" />

      <Container fluid={true}>
        <Row>
          <Col md="2 mb-3">
            <Label>Filter By Start Date</Label>
            <DatePicker
              className="form-control digits"
              placeholderText="Filter by start date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Col>
          <Col md="2 mb-3">
            <Label>Filter By End Date</Label>
            <DatePicker
              className="form-control digits"
              placeholderText="Filter by end date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Col>
        </Row>
        <Row className="second-chart-list third-news-update">
          <Col xl="6" lg="6" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  {/* <Col xl="3" lg="12" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" lg="12" className="p-0 left_side_earning">
                        <h5>Module Trends</h5>
                        <p className="font-roboto">{'Overview'}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {metrics?.maxPower?.converted_power?.toFixed(3)} KWh{' '}
                        </h5>
                        <p className="font-roboto">{`Best Creation ${timeAggregator}`}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {' '}
                          {metrics?.minPower?.converted_power?.toFixed(
                            3
                          )} KWh{' '}
                        </h5>
                        <p className="font-roboto">{`Best Sales ${timeAggregator}`}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {metrics?.maxPower?.timestamp
                            ? moment(metrics?.maxPower?.timestamp).format(
                                'MMM yyyy'
                              )
                            : ''}
                        </h5>
                        <p className="font-roboto">{'Best Power Month'}</p>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col xl="" lg="12" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li>{"Daily"}</li>
                              <li>{"Monthly"}</li>
                              <li>{"Yearly"}</li>
                            </ul>
                          </div>
                        </Col>
                        <Col
                          xl="4"
                          md="4"
                          sm="4"
                          className="col-12 p-0 justify-content-end"
                        ></Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts
                                id="chart-currently"
                                options={{
                                  colors: [primary, secondary],
                                  fill: {
                                    type: "gradient",
                                    gradient: {
                                      shadeIntensity: 1,
                                      opacityFrom: 0.7,
                                      opacityTo: 0.5,
                                      stops: [0, 80, 100],
                                    },
                                  },
                                  stroke: {
                                    width: 3,
                                  },
                                  grid: {
                                    show: false,
                                  },
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  xaxis: {
                                    type: "datetime",
                                    tickAmount: 1,
                                    min: startDate?.getTime(),
                                    title: {
                                      text: "Time",
                                    },
                                  },
                                  yaxis: {
                                    title: {
                                      text: "Module Count",
                                    },
                                    decimalsInFloat: 3,
                                  },
                                }}
                                series={[
                                  ...Object.values(trends).map((trend, i) => {
                                    return {
                                      name: Object.keys(trends)[i],
                                      data: trend.map((trendItem) => ({
                                        y: trendItem?.count,
                                        x: new Date(
                                          trendItem?.timestamp
                                        ).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                                      })),
                                    };
                                  }),
                                ]}
                                type="area"
                                height={275}
                              />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6" lg="6" className="notification box-col-6">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Claims Analytics</h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [primary, secondary],
                      labels: claimData.map((data) => data.status),
                    }}
                    series={claimData.map((data) => data.count)}
                    type="donut"
                    height={225}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* lower cards */}
          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesCreated?.count}</h4>
                          <span>{"Modules Created"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesCreated?.efficiency / 1000
                            ).toFixed(3)}{" "}
                            KW
                          </h4>
                          <span>{"Modules Capacity"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesSold?.count}</h4>
                          <span>{"Modules Sold"} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(metrics?.modulesSold?.efficiency / 1000).toFixed(3)}{" "}
                            KW
                          </h4>
                          <span>{"Module Capacity"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesRegistered?.count}</h4>
                          <span>{"Modules Registered"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesRegistered?.efficiency / 1000
                            ).toFixed(3)}{" "}
                            KW
                          </h4>
                          <span>{"Modules Capacity"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesInstalled?.count}</h4>
                          <span>{"Modules Installed"} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesInstalled?.efficiency / 1000
                            ).toFixed(3)}{" "}
                            KW
                          </h4>
                          <span>{"Module Capacity"}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesInstalled?.count}</h4>
                          <span>{'Modules Installed'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesInstalled?.efficiency / 100
                            ).toFixed(3)}{' '}
                            KW
                          </h4>
                          <span>{'Modules Capaicity'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesRegistered?.count}</h4>
                          <span>{'Modules Purchased'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesRegistered?.efficiency / 3
                            ).toFixed(3)}{' '}
                            KW
                          </h4>
                          <span>{'Module Capacity'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}

          {/* <Col xl="4 xl-50" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <span>{'Warranty Claims Filed'}</span>
                  </div>
                  <div className="knob-block text-center">
                    <div className="knob1" id="ordervalue1"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminDashboard;
