import React, { Fragment, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table } from "reactstrap";
import { getPlantListData } from "../../../../api";
import { SET_ACTIVE_PLANT } from "../../../../redux/actionTypes";
import { primary } from "../string/StringChartData";
import AddPlantModal from "./AddPlantModal";
import { acivePlantDispatcher } from "../../../../redux/plant/action";
import DeletePlantModal from "../plant/deleteModal";
import EditPlantModal from "./EditPlantModal";

const PlantTable = ({ refetch, setRefetch }) => {
  const [plantList, setPlantList] = useState([]);
  const [activePlant, setAcivePlant] = useState(null);
  const [activePlantIndex, setAcivePlantIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  const deletetoggle = () => setShowDeleteModal(!showDeleteModal);

  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await getPlantListData();
    setPlantList(data.data);
    setAcivePlant(data.data[0]);
    dispatch(acivePlantDispatcher(data?.data[0]?._id));
  };

  const handleActivePlant = (index, plant) => {
    setAcivePlant(plant);
    setAcivePlantIndex(index);
    // dispatch({
    //   type: SET_ACTIVE_PLANT,
    //   payload: {plantId: plant?._id},
    // });
    dispatch(acivePlantDispatcher(plant?._id));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  return (
    <Fragment>
      {/* <Breadcrumb parent="Tables" title="Basic Tables" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Plant Name"}</th>
                      <th scope="col">{"Plant Location"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plantList?.map((plant, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => handleActivePlant(index, plant)}
                          style={
                            activePlantIndex === index
                              ? { boxShadow: `-2px 1px 10px ${primary}` }
                              : {}
                          }
                        >
                          <th scope="row">{index + 1}</th>
                          <td>
                            {" "}
                            <Link to={`/plant/${plant?.id}`}>
                              {plant?.name}
                            </Link>
                          </td>
                          <td>{plant?.address?.raw_address}</td>
                          <td>
                            <div>
                              <Edit
                                color="#7366ff"
                                onClick={() => {
                                  setShowModal(!showModal);
                                }}
                                style={{ cursor: "pointer" }}
                              />{" "}
                              {/* &nbsp;
                               <Trash2
                                onClick={() => {
                                  setShowDeleteModal(!showDeleteModal);
                                  setSelectedPlantId(plant?.id);
                                }}
                                style={{ cursor: "pointer" }}
                                color="crimson"
                              /> */}
                            </div>
                          </td>
                          {/* <AddPlantModal
                            showModal={showModal}
                            Modaltoggle={Modaltoggle}
                          /> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <EditPlantModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  Modaltoggle={Modaltoggle}
                  setRefetch={setRefetch}
                  plantId={activePlant?._id}
                />
                <DeletePlantModal
                  showModal={showDeleteModal}
                  setShowModal={setShowDeleteModal}
                  Modaltoggle={deletetoggle}
                  setRefetch={setRefetch}
                  plantId={activePlant?._id}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PlantTable;
