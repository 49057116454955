import {SET_ACTIVE_BLOCK} from '../actionTypes';

const initial_state = {
  blockId: null,
};

const blockReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACTIVE_BLOCK:
      return {...state, blockId: action.payload.blockId};

    default:
      return {...state};
  }
};
export default blockReducer;
