import React, { Fragment, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, Table } from "reactstrap";
import { getInverterListData } from "../../../../api";
import { aciveInverterDispatcher } from "../../../../redux/inverter/action";
import { primary } from "../string/StringChartData";
import AddInverterModal from "./AddInverterModal";

const InverterTable = ({ showModal, Modaltoggle, refetch }) => {
  const [inverterList, setInverterList] = useState([]);
  const [activeInverter, setAciveInverter] = useState(null);
  const [activeInverterIndex, setAciveInverterIndex] = useState(0);

  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await getInverterListData();
    setInverterList(data.data);
    dispatch(aciveInverterDispatcher(data?.data?.[0]?._id));
  };

  const handleActiveInverter = (index, inverter) => {
    setAciveInverter(inverter);
    setAciveInverterIndex(index);
    dispatch(aciveInverterDispatcher(inverter?._id));
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  return (
    <Fragment>
      {/* <Breadcrumb parent="Tables" title="Basic Tables" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"Inverter ID"}</th>
                      <th scope="col">{"Inverter Serial Number"}</th>
                      <th scope="col">{"Model Number"}</th>
                      <th scope="col">{"Block"}</th>
                      <th scope="col">{"Address"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inverterList?.map((inverter, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => handleActiveInverter(index, inverter)}
                          style={
                            activeInverterIndex === index
                              ? { boxShadow: `-2px 1px 10px ${primary}` }
                              : {}
                          }
                        >
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link to={`/inverter/${inverter?.id}`}>
                              {inverter?.id}
                            </Link>
                          </td>
                          <td>{inverter?.serial_number}</td>
                          <td>{inverter?.model_number}</td>
                          <td>
                            <Link to={`/block/${inverter?.block_id}`}>
                              {inverter?.block?.name}
                            </Link>
                          </td>
                          <td>{inverter?.address?.raw_address}</td>
                          <td>
                            <div className="d-flex" onClick={Modaltoggle}>
                              <Edit color="#7366ff" />
                              &nbsp;
                              {/* <Trash2 color="crimson" /> */}
                            </div>
                          </td>
                          <AddInverterModal
                            showModal={showModal}
                            Modaltoggle={Modaltoggle}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InverterTable;
