import React, { Fragment, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
// import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table } from "reactstrap";
import { getStringListData } from "../../../../api";
import AddStringModal from "./AddStringModal";

const StringTable = ({ showModal, Modaltoggle, filterResult, refetch }) => {
  const [stringList, setStringList] = useState([]);
  const getData = async () => {
    const data = await getStringListData();
    const updatedData = data.filter(
      (item) => item.status === filterResult.value
      // console.log(filterResult.value === item.status, item, filterResult)
    );
    setStringList(filterResult.value === "ALL" ? data : updatedData);
  };

  useEffect(() => {
    getData();
  }, [filterResult?.value]);

  useEffect(() => {
    if (refetch) {
      getData();
    }
  }, [refetch]);

  console.log(stringList, "stringList");
  return (
    <Fragment>
      {/* <Breadcrumb parent="Tables" title="Basic Tables" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader></CardHeader> */}
              <div className="table-responsive">
                <Table borderless>
                  <thead>
                    <tr>
                      <th scope="col">{"#"}</th>
                      <th scope="col">{"String ID"}</th>
                      <th scope="col">{"String Name"}</th>

                      <th scope="col">{"Inverter ID"}</th>
                      <th scope="col">{"Status"}</th>
                      <th scope="col">{"Max Power"}</th>
                      <th scope="col">{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stringList?.map((string, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link to={`/string/${string?.id}`}>
                              {string?.id}
                            </Link>
                          </td>
                          <td>{string?.name}</td>

                          <td>{string?.inverter_id}</td>
                          <td>{string?.status}</td>
                          <td>{string?.max_power}</td>
                          <td>
                            <div onClick={Modaltoggle}>
                              <Edit color="#7366ff" />
                              &nbsp;
                              {/* <Trash2 color="crimson" /> */}
                            </div>
                          </td>
                          <AddStringModal
                            showModal={showModal}
                            Modaltoggle={Modaltoggle}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StringTable;
