import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, Route, Routes } from "react-router";
import AppLayout from "./components/layout";
import Default from "./components/dashboard/default";
import Traceablity from "./components/application/Solar-app/traceablity";
import SolarMonitoring from "./components/application/Solar-app/solar-monitoring";
import RegisterModules from "./components/application/Solar-app/register-modules";
import Plant from "./components/application/Solar-app/plant";
import Block from "./components/application/Solar-app/block";
import Inverter from "./components/application/Solar-app/inverter";
import String from "./components/application/Solar-app/string";
import Module from "./components/application/Solar-app/module";
import PlantDetails from "./components/application/Solar-app/plant/PlantDetails";
import StringDetails from "./components/application/Solar-app/string/StringDetails";
import BlockDetails from "./components/application/Solar-app/block/BlockDetails";
import InverterDetails from "./components/application/Solar-app/inverter/InverterDetails";
import ModulesDetails from "./components/application/Solar-app/modules/ModulesDetails";
import WarrantyClaims from "./components/application/Solar-app/Claims/WarrantyClaims";
import TestDetails from "./components/application/Solar-app/modules/TestDetails";
import Loader from "./layout/loader";
import Signin from "./auth/signin";
import Signup from "./auth/signup";
import { PrivateRoute, AuthRoute } from "./components/ProtectedRoute";
import { WarrantyDetails } from "./components/application/Solar-app/Claims/WarrantyDetails";
import Recycler from "./components/recycler";

const App = () => (
  <div className="App">
    <Provider store={store}>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login" element={<AuthRoute />}>
            <Route path="/login" element={<Signin />} />
          </Route>
          <Route path="/signup" element={<AuthRoute />}>
            <Route path="/signup" element={<Signup />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              exact
              path="/"
              element={
                // <AppLayout>
                //   <Default />
                // </AppLayout>
                <Navigate to="/dashboard" />
              }
            />
          </Route>
          <Route element={<AppLayout />}>
            <Route path={"/"} element={<Navigate to="/dashboard" />} />
            <Route path={"/dashboard"} element={<Default />} />
            <Route path={"/traceablity"} element={<Traceablity />} />
            <Route path={"/solar"} element={<SolarMonitoring />} />
            <Route path={"/plant"} element={<Plant />} />
            <Route path={"/block"} element={<Block />} />
            <Route path={"/inverter"} element={<Inverter />} />
            <Route path={"/string"} element={<String />} />
            <Route path={"/Modules"} element={<Module />} />
            <Route path={"/claims"} element={<WarrantyClaims />} />
            <Route path={"/plant/:id"} element={<PlantDetails />} />
            <Route path={"/string/:id"} element={<StringDetails />} />
            <Route path={"/block/:id"} element={<BlockDetails />} />
            <Route path={"inverter/:id"} element={<InverterDetails />} />
            <Route path={"/Modules/:id"} element={<ModulesDetails />} />
            <Route path={"/tests/:id"} element={<TestDetails />} />
            <Route path={"/claims/:id"} element={<WarrantyDetails />} />
            <Route path={"/recycler"} element={<Recycler />} />
          </Route>
        </Routes>
      </Suspense>
    </Provider>
  </div>
);

export default App;
