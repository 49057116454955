import React, { useState } from "react";
// import Plantchart from "./plant/PlantChart";
import { Plus } from "react-feather";
// import PlantTable from "./plant/PlantTable";
// import AddBlockModal from "./block/AddBlockModal";
import AddStringModal from "./string/AddStringModal";
import StringTable from "./string/StringTable";
import Stringchart from "./string/StringChart";
import { Label } from "reactstrap";

const filterTypes = [
  { id: 1, name: "All", value: "ALL" },
  { id: 1, name: "Healthy", value: "HEALTHY" },
  { id: 2, name: "Unhealthy", value: "UNHEALTHY" },
];

function String() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [filterResult, setFilterResult] = useState(filterTypes[0]);
  const Modaltoggle = () => setShowModal(!showModal);

  return (
    <div>
      <Stringchart />
      <div className="container">
        <div className="d-flex justify-content-between mb-3 mt-3 align-items-center">
          <h5>String </h5>
          <div className="d-flex gap-4 align-items-end">
            <div>
              <Label>{"Filter by status"}</Label>
              <select
                className="form-control"
                style={{ padding: "5px 8px" }}
                onChange={(e) => {
                  const result = filterTypes?.filter(
                    (item) => item.name === e.target.value
                  )[0];
                  setFilterResult(result);
                }}
              >
                {" "}
                {filterTypes?.map((el) => {
                  return <option id={el.id}>{el.name}</option>;
                })}
              </select>
              <AddStringModal showModal={showModal} Modaltoggle={Modaltoggle} />
            </div>
            <button
              onClick={Modaltoggle}
              style={{ border: "none", borderRadius: "5px" }}
              className="badge-light-primary btn-mail d-flex p-2 align-items-center"
            >
              <Plus className="me-2" />
              Add String
            </button>
            <AddStringModal
              showModal={showModal}
              setShowModal={setShowModal}
              Modaltoggle={Modaltoggle}
              setRefetch={setRefetch}
            />
          </div>
        </div>
      </div>
      <StringTable filterResult={filterResult} refetch={refetch} />
    </div>
  );
}

export default String;
