import axios from "axios";
import { Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { BASE_API_URL } from "../../../../api";
import jwtDecode from "jwt-decode";

const WarrantyClaimModal = ({
  showClaimModal,
  setShowClaimModal,
  ClaimModaltoggle,
  moduleId,
}) => {
  const token = localStorage.getItem("token");
  let decoded_user_id = jwtDecode(token);
  const { id } = useParams();
  const navigate = useNavigate();
  const obj = {
    module_id: id,
    user_id: decoded_user_id?.id,
    description: "",
  };
  const [claimObj, setClaimObj] = useState(obj);

  const handleSubmit = async () => {
    let finalObj = claimObj;
    try {
      const res = await axios.post(`${BASE_API_URL}/claim`, finalObj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res, "res of post claim");
      if (res.data.status === true) {
        setShowClaimModal(false);
        toast.success("Warranty claimed successfully");
        navigate(`/claims/${res.data.data.id}`, { replace: true });
      }
    } catch (error) {
      setShowClaimModal(false);
      toast.error("Something went Wrong");
    }
  };
  return (
    <Fragment>
      <Modal isOpen={showClaimModal} toggle={ClaimModaltoggle} size="lg">
        <ModalHeader toggle={ClaimModaltoggle}>
          {"Warranty Claim Form"}
        </ModalHeader>
        <ModalBody>
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""

              //   onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Description"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    type="text"
                    placeholder="Description"
                    onChange={(e) =>
                      setClaimObj({ ...claimObj, description: e.target.value })
                    }
                    // {...register("location", { required: true })}
                  />
                  {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                </Col>
              </Row>

              <FormGroup>
                <div className="form-check">
                  {/* <div className="checkbox p-0">
                    <Input
                      className="form-check-input"
                      id="invalidCheck"
                      type="checkbox"
                    />
                    <Label className="form-check-label" htmlFor="invalidCheck">
                      {"Agree to terms and conditions"}
                    </Label>
                  </div> */}
                  {/* <div className="invalid-feedback">
                    {"You must agree before submitting."}
                  </div> */}
                </div>
              </FormGroup>
              {/* <Button color="primary">{"Submit form"}</Button> */}
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={ClaimModaltoggle}>
            {"Close"}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {"Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default WarrantyClaimModal;
