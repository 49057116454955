import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import {
  Button,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { create as ipfsHttpClient } from "ipfs-http-client";
import axios from "axios";
import { BASE_API_URL } from "../../../../api";
import { toast } from "react-toastify";
import { Buffer } from "buffer";
import { useEffect } from "react";

export const AddAttachmentsModal = ({
  showAttachmentModal,
  setshowAttachmentModal,
  attachmentModal,
  claimID,
}) => {
    let token = localStorage.getItem("token");
    const [attachmentFiles, setAttachmentFiles] = useState([]);

  
  const authParam = `${"2FwEAiZi3XEzu3SbttRO3IxbExl"}:${"4cf7f0d1feadbabb27f3360c7bb7e951"}`;

  const client = ipfsHttpClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: `Basic ${Buffer.from(authParam).toString("base64")}`,
    },
  });
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    setAttachmentFiles(Array.from(new Set([...attachmentFiles, file])));
  };

  const handleSubmit = (files) => {
    console.log(files.map((f) => f.meta));
  };

  const submitIpfs = async () => {
    let uploadedFiles = attachmentFiles.map(async (file) => {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      return {
        type: "image",
        path: added.path,
      };
    });
    let attachments = await Promise.all(uploadedFiles);
    return attachments;
    // if (!attachmentFiles) return;
  };

  const handleSubmitAttachments = async() => {
    
    let attachments = await submitIpfs();
    console.log(attachments, "attachments after submit ipfs")
    let finalObj = {
        claim_id: claimID,
        path: attachments[0]?.path,
        type: attachments[0]?.type,
        

    };
    try {
        const res = await axios.post(`${BASE_API_URL}/attachment`, finalObj, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if(res.data.status === "success"){
            setshowAttachmentModal(false)
            toast.success("Attachments Uploaded successfully!")
        }

        console.log(res, "response for attachments")
    }catch(error){
        toast.error("Error occured while adding attachments")
    }

  };

  return (
    <Modal isOpen={showAttachmentModal} toggle={attachmentModal} size="xl">
      <ModalHeader>Add Test</ModalHeader>
      <ModalBody>
        <CardBody>
          <Form className="needs-validation" noValidate="">
            <Col md="3 mb-3">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Label style={{ width: "100%", marginRight: "1rem" }}>
                  {"Claim ID"}
                </Label>

                <input
                  style={{ width: "15rem" }}
                  className="form-control"
                  name="claimId"
                  type="text"
                  placeholder="Claim ID"
                  value={claimID}
                />
              </div>
            </Col>

            <h5>{"Add Attachment"}</h5>
            <div className="dz-message needsclick">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                accept="image/*,application/pdf"
              />
            </div>
          </Form>
        </CardBody>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmitAttachments} color="primary">
          Submit
        </Button>
        <Button color="secondary" onClick={attachmentModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
