import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useParams } from "react-router";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { Buffer } from "buffer";
import {
  Button,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { uploadTests } from "../../../../api";
import moment from "moment";
import { useEffect } from "react";
import { dateNow } from "../../../functions/utilities";

const ivCrurveState = {
  testingIrradiation: "",
  irradiationUnit: "",
  testingPerformance: "",
  temperatureUnit: "",
  voc: "",
  isc: "",
  vmp: "",
  imp: "",
};

const flashState = {
  testingIrradiation: "",
  irradiationUnit: "",
  testingPerformance: "",
  temperatureUnit: "",
  voc: "",
  isc: "",
  vmp: "",
  imp: "",
};

const thermographyState = {
  maxTemperature: "",
  minTemperature: "",
  averageTemperature: "",
  temperatureUnit: "",
  temperatureDifference: "",
  hotspotSeverity: "",
};

const elState = {
  microCracksPercent: "",
  cracksPercent: "",
  spidersCount: "",
  deadcellCount: "",
  backSheetScratch: "",
  bypassDiode: "",
  currentMismatch: "",
  solderingIssue: "",
};

const AddTestModal = ({ showModal, Modaltoggle, setShowModal, setRefetch }) => {
  const [testType, setTestType] = useState("");
  const [ivCrurveValues, setIvCurveValues] = useState(ivCrurveState);
  const [flashValues, setFlashValues] = useState(flashState);
  const [thermographyValues, setThermographyValues] =
    useState(thermographyState);
  const [elValues, setElValues] = useState(elState);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [finalAttachments, setFinalAttachments] = useState([]);
  let currentDate = dateNow();
  console.log(currentDate, "currentDate");
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  console.log(date, "date");
  const [comment, setComment] = useState("");

  const authParam = `${"2FwEAiZi3XEzu3SbttRO3IxbExl"}:${"4cf7f0d1feadbabb27f3360c7bb7e951"}`;

  console.log(testType, "testType");

  const testTypes = [
    { id: 1, name: "IV-CURVE" },
    { id: 2, name: "FLASH" },
    { id: 3, name: "THERMOGRAPHY" },
    { id: 4, name: "EL" },
  ];

  const { id } = useParams();

  const uploadTestData = async (type, values) => {
    setIsLoading(true);
    let attachments = await submitIpfs();
    let finalObj = {
      type: type,
      module_id: id,
      ...values,
      date: (date && moment(date).toISOString()) || currentDate,
      attachments: attachments,
      comments: comment,
    };
    const testResponse = await uploadTests(finalObj);
    if (testResponse) {
      setIsLoading(false);
      setRefetch(true);
      setShowModal(false);
    }
    console.log(testResponse);
    setIsLoading(false);
  };

  const client = ipfsHttpClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: `Basic ${Buffer.from(authParam).toString("base64")}`,
    },
  });

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    setAttachmentFiles(Array.from(new Set([...attachmentFiles, file])));
  };

  const handleSubmit = (files) => {
    console.log(files.map((f) => f.meta));
  };

  const submitIpfs = async () => {
    let uploadedFiles = attachmentFiles.map(async (file) => {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      return {
        type: "image",
        path: added.path,
      };
    });
    let attachments = await Promise.all(uploadedFiles);
    return attachments;
    // if (!attachmentFiles) return;
  };

  console.log(attachmentFiles, "attachment files");

  return (
    <Modal isOpen={showModal} toggle={Modaltoggle} size="xl">
      <ModalHeader>Add Test</ModalHeader>
      <ModalBody>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            //   onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="3 mb-3">
                <Label>{"Test Type"}</Label>

                <select
                  style={{ marginLeft: "1rem", padding: "5px 8px" }}
                  onChange={(e) => setTestType(e.target.value)}
                >
                  {testTypes?.map((el) => {
                    return <option id={el.id}>{el.name}</option>;
                  })}
                </select>

                {/* <span>{errors.plantName && "Plant name is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div> */}
              </Col>
              <Col md="3 mb-3">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label style={{ width: "57%" }}>{"Select Date"}</Label>

                  <input
                    className="form-control"
                    name="timestamp"
                    type="datetime-local"
                    placeholder="Date and Time"
                    value={date || currentDate}
                    onChange={(e) => setDate(e.target.value)}
                    // (moment(e.target.value).format("lll")
                    // {...register("location", { required: true })}
                  />
                </div>
                {/* <span>{errors.plantName && "Plant name is required"}</span>
                  <div className="valid-feedback">{"Looks good!"}</div> */}
              </Col>
            </Row>

            {/* IV-CURVE */}

            {testType === "IV-CURVE" ? (
              <>
                <Row>
                  <h5>IV-CURVE Test</h5>
                  {/* location coordinates */}
                  <Col md="3 mb-3">
                    <Label>{"Testing Irradiation"}</Label>
                    <input
                      className="form-control"
                      name="testingIrradiation"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          testingIrradiation: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.testingIrradiation}
                      placeholder="Testing Irradiation"
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                  </Col>
                  {/* address Name */}
                  <Col md="4 mb-3">
                    <Label>{"Irradiation Unit"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="irradiationUnit"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          irradiationUnit: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.irradiationUnit}
                      placeholder="Irradiation Unit"
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Testing Performance"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="testingPerformance"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          testingPerformance: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.testingPerformance}
                      placeholder="Testing Performance"
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="2 mb-3">
                    <Label>{"Temperature Unit"}</Label>
                    <input
                      className="form-control"
                      name="temperatureUnit"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          temperatureUnit: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.temperatureUnit}
                      placeholder="Temperature Unit"
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
    {errors.location && "Please provide location coordinates"}
  </span>
  <div className="invalid-feedback">
    {"Please provide location coordinates."}
  </div> */}
                  </Col>

                  <Col md="2 mb-3">
                    <Label>{"voc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="voc"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          voc: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.voc}
                      placeholder="voc"
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
    {errors.addressName && "Please Provide Address Name"}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Address Name"}
  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"isc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="isc"
                      type="text"
                      placeholder="isc"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          isc: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.isc}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"vmp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="vmp"
                      type="text"
                      placeholder="vmp"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          vmp: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.vmp}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"imp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="imp"
                      type="text"
                      placeholder="imp"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          imp: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.imp}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )}

            {testType === "FLASH" ? (
              <>
                <Row>
                  <h5>FLASH Test</h5>
                  {/* location coordinates */}
                  <Col md="3 mb-3">
                    <Label>{"Testing Irradiation"}</Label>
                    <input
                      className="form-control"
                      name="testingIrradiation"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          testingIrradiation: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.testingIrradiation}
                      placeholder="Testing Irradiation"
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                  </Col>
                  {/* address Name */}
                  <Col md="4 mb-3">
                    <Label>{"Irradiation Unit"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="irradiationUnit"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          irradiationUnit: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.irradiationUnit}
                      placeholder="Irradiation Unit"
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Testing Performance"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="testingPerformance"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          testingPerformance: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.testingPerformance}
                      placeholder="Testing Performance"
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="2 mb-3">
                    <Label>{"Temperature Unit"}</Label>
                    <input
                      className="form-control"
                      name="temperatureUnit"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          temperatureUnit: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.temperatureUnit}
                      placeholder="Temperature Unit"
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
    {errors.location && "Please provide location coordinates"}
  </span>
  <div className="invalid-feedback">
    {"Please provide location coordinates."}
  </div> */}
                  </Col>

                  <Col md="2 mb-3">
                    <Label>{"voc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="voc"
                      type="text"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          voc: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.voc}
                      placeholder="voc"
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
    {errors.addressName && "Please Provide Address Name"}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Address Name"}
  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"isc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="isc"
                      type="text"
                      placeholder="isc"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          isc: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.isc}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"vmp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="vmp"
                      type="text"
                      placeholder="vmp"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          vmp: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.vmp}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"imp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="imp"
                      type="text"
                      placeholder="imp"
                      onChange={(e) =>
                        setIvCurveValues((prev) => ({
                          ...prev,
                          imp: e.target.value,
                        }))
                      }
                      value={ivCrurveValues?.imp}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )}

            {/* FLASH */}
            {/* {testType === "FLASH" ? (
              <>
                <Row>
                  <h5>FLASH Test</h5>
                  
                  <Col md="3 mb-3">
                    <Label>{"Testing Irradiation"}</Label>
                    <input
                      className="form-control"
                      name="testingIrradiation"
                      type="text"
                      placeholder="Testing Irradiation"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          testingIrradiation: e.target.value,
                        }))
                      }
                      value={flashValues?.testingIrradiation}
                      // {...register("location", { required: true })}
                    />

                  </Col>
                  
                  <Col md="4 mb-3">
                    <Label>{"Irradiation Unit"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="irradiationUnit"
                      type="text"
                      placeholder="Irradiation Unit"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          irradiationUnit: e.target.value,
                        }))
                      }
                      value={flashValues?.irradiationUnit}
                      // {...register("addressName", { required: true })}
                    />

                  </Col>
                  
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Testing Performance"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="testingPerformance"
                      type="text"
                      placeholder="Testing Performance"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          testingPerformance: e.target.value,
                        }))
                      }
                      value={flashValues?.irradiationUnit}
                      // {...register("localArea", { required: true })}
                    />

                  </Col>
                </Row>

                <Row>
                  <Col md="2 mb-3">
                    <Label>{"Temperature Unit"}</Label>
                    <input
                      className="form-control"
                      name="temperatureUnit"
                      type="text"
                      placeholder="Temperature Unit"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          temperatureUnit: e.target.value,
                        }))
                      }
                      value={flashValues?.temperatureUnit}
                      // {...register("location", { required: true })}
                    />
                  </Col>

                  <Col md="2 mb-3">
                    <Label>{"voc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="voc"
                      type="text"
                      placeholder="voc"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          voc: e.target.value,
                        }))
                      }
                      value={flashValues?.voc}
                      // {...register("addressName", { required: true })}
                    />
                  </Col>
                  
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"isc"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="isc"
                      type="text"
                      placeholder="isc"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          isc: e.target.value,
                        }))
                      }
                      value={flashValues?.isc}
                      // {...register("localArea", { required: true })}
                    />
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"vmp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="vmp"
                      type="text"
                      placeholder="vmp"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          vmp: e.target.value,
                        }))
                      }
                      value={flashValues?.vmp}
                      // {...register("localArea", { required: true })}
                    />
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"imp"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="imp"
                      type="text"
                      placeholder="imp"
                      onChange={(e) =>
                        setFlashValues((prev) => ({
                          ...prev,
                          imp: e.target.value,
                        }))
                      }
                      value={flashValues?.imp}
                      // {...register("localArea", { required: true })}
                    />

                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )} */}

            {/* THERMOGRAPHY */}
            {testType === "THERMOGRAPHY" ? (
              <>
                <Row>
                  <h5>THERMOGRAPHY Test</h5>
                  {/* location coordinates */}
                  <Col md="3 mb-3">
                    <Label>{"Max Temperature"}</Label>
                    <input
                      className="form-control"
                      name="maxTemperature"
                      type="text"
                      placeholder="Max Temperature"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          maxTemperature: e.target.value,
                        }))
                      }
                      value={thermographyValues?.maxTemperature}
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                  </Col>
                  {/* address Name */}
                  <Col md="4 mb-3">
                    <Label>{"Min Temperature"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="minTemperature"
                      type="text"
                      placeholder="Min Temperature"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          minTemperature: e.target.value,
                        }))
                      }
                      value={thermographyValues?.minTemperature}
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Average Temperature"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="averageTemperature"
                      type="text"
                      placeholder="Average Temperature"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          averageTemperature: e.target.value,
                        }))
                      }
                      value={thermographyValues?.averageTemperature}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="2 mb-3">
                    <Label>{"Temperature Unit"}</Label>
                    <input
                      className="form-control"
                      name="temperatureUnit"
                      type="text"
                      placeholder="Temperature Unit"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          temperatureUnit: e.target.value,
                        }))
                      }
                      value={thermographyValues?.temperatureUnit}
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
    {errors.location && "Please provide location coordinates"}
  </span>
  <div className="invalid-feedback">
    {"Please provide location coordinates."}
  </div> */}
                  </Col>

                  <Col md="2 mb-3">
                    <Label>{"Temperature Difference"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="temperatureDifference"
                      type="text"
                      placeholder="Temperature Difference"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          temperatureDifference: e.target.value,
                        }))
                      }
                      value={thermographyValues?.temperatureDifference}
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
    {errors.addressName && "Please Provide Address Name"}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Address Name"}
  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Hotspot Severity"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="hotspotSeverity"
                      type="text"
                      placeholder="Hotspot Severity"
                      onChange={(e) =>
                        setThermographyValues((prev) => ({
                          ...prev,
                          hotspotSeverity: e.target.value,
                        }))
                      }
                      value={thermographyValues?.hotspotSeverity}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )}

            {/* EL */}
            {testType === "EL" ? (
              <>
                <Row>
                  <h5>EL Test</h5>
                  {/* location coordinates */}
                  <Col md="3 mb-3">
                    <Label>{"Micro Cracks Percentage"}</Label>
                    <input
                      className="form-control"
                      name="microCracksPercent"
                      type="text"
                      placeholder="Micro Cracks Percentage"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          microCracksPercent: e.target.value,
                        }))
                      }
                      value={elValues?.microCracksPercent}
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
                    {errors.location && "Please provide location coordinates"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide location coordinates."}
                  </div> */}
                  </Col>
                  {/* address Name */}
                  <Col md="4 mb-3">
                    <Label>{"Cracks Percent"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="cracksPercent"
                      type="text"
                      placeholder="Cracks Percent"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          cracksPercent: e.target.value,
                        }))
                      }
                      value={elValues?.cracksPercent}
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
                    {errors.addressName && "Please Provide Address Name"}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Address Name"}
                  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="4 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Spiders Count"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="spidersCount"
                      type="text"
                      placeholder="Spiders Count"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          cracksPercent: e.target.value,
                        }))
                      }
                      value={elValues?.spidersCount}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
                    {errors.localArea && "Please provide a Local Area."}
                  </span>
                  <div className="invalid-feedback">
                    {"Please provide a Local Area."}
                  </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="2 mb-3">
                    <Label>{"Dead Cell Count"}</Label>
                    <input
                      className="form-control"
                      name="deadcellCount"
                      type="text"
                      placeholder="Dead Cell Count"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          deadcellCount: e.target.value,
                        }))
                      }
                      value={elValues?.deadcellCount}
                      // {...register("location", { required: true })}
                    />
                    {/* <span>
    {errors.location && "Please provide location coordinates"}
  </span>
  <div className="invalid-feedback">
    {"Please provide location coordinates."}
  </div> */}
                  </Col>

                  <Col md="2 mb-3">
                    <Label>{"Back Sheet Scratch"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom04"
                      name="backSheetScratch"
                      type="text"
                      placeholder="Back Sheet Scratch"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          backSheetScratch: e.target.value,
                        }))
                      }
                      value={elValues?.backSheetScratch}
                      // {...register("addressName", { required: true })}
                    />
                    {/* <span>
    {errors.addressName && "Please Provide Address Name"}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Address Name"}
  </div> */}
                  </Col>
                  {/* local area */}
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">{"Bypass Diode"}</Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="bypassDiode"
                      type="text"
                      placeholder="Bypass Diode"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          bypassDiode: e.target.value,
                        }))
                      }
                      value={elValues?.bypassDiode}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Current mismatch"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="currentMismatch"
                      type="text"
                      placeholder="Current mismatch"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          currentMismatch: e.target.value,
                        }))
                      }
                      value={elValues?.currentMismatch}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                  <Col md="2 mb-3">
                    <Label htmlFor="validationCustom05">
                      {"Soldering Issue"}
                    </Label>
                    <input
                      className="form-control"
                      id="validationCustom05"
                      name="solderingIssue"
                      type="text"
                      placeholder="Soldering Issue"
                      onChange={(e) =>
                        setElValues((prev) => ({
                          ...prev,
                          solderingIssue: e.target.value,
                        }))
                      }
                      value={elValues?.solderingIssue}
                      // {...register("localArea", { required: true })}
                    />
                    {/* <span>
    {errors.localArea && "Please provide a Local Area."}
  </span>
  <div className="invalid-feedback">
    {"Please provide a Local Area."}
  </div> */}
                  </Col>
                </Row>
              </>
            ) : (
              <div></div>
            )}

            <FormGroup>
              <div className="form-check">
                {/* <div className="checkbox p-0">
                    <Input
                      className="form-check-input"
                      id="invalidCheck"
                      type="checkbox"
                    />
                    <Label className="form-check-label" htmlFor="invalidCheck">
                      {"Agree to terms and conditions"}
                    </Label>
                  </div> */}
                {/* <div className="invalid-feedback">
                    {"You must agree before submitting."}
                  </div> */}
              </div>
            </FormGroup>
            {/* <Button color="primary">{"Submit form"}</Button> */}
          </Form>
          <h5>{"Add Attachment"}</h5>
          <div className="dz-message needsclick">
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              accept="image/*"
            />
          </div>
          <div className="mt-5">
            <textarea
              className="form-control"
              name="comment"
              type="text"
              placeholder="Add Comment"
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </CardBody>
      </ModalBody>
      <ModalFooter>
        <Button
          style={isLoading ? { cursor: "not-allowed" } : {}}
          className={isLoading && `disabled`}
          onClick={() =>
            testType === "IV-CURVE"
              ? uploadTestData("IV-CURVE", ivCrurveValues)
              : testType === "FLASH"
              ? uploadTestData("FLASH", flashValues)
              : testType === "THERMOGRAPHY"
              ? uploadTestData("THERMOGRAPHY", thermographyValues)
              : testType === "EL"
              ? uploadTestData("EL", elValues)
              : ""
          }
          color="primary"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
        <Button color="secondary" onClick={Modaltoggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTestModal;
