import React, { useState } from "react";
import RegisterModule from "./register-modules";
import { Button, Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import ModuleTable from "./modules/ModuleTable";
import { toast, ToastContainer } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import { BASE_API_URL } from "../../../api";
import Breadcrumbs from "../../../layout/breadcrumb";

function Module() {
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const Modaltoggle = () => setShowModal(!showModal);

  const [excelSheet, setExcelSheet] = useState(null);
  console.log(excelSheet, "excel sheet");
  const handleChangeStatus = (e) => {
    let files = e.target.files;
    console.log(files, "e.tragyt.files");
    setExcelSheet(files);
  };
  const handleSubmit = async () => {
    let token = localStorage.getItem("token");
    let file = excelSheet;
    console.log(file, "fileeeee");
    let formData = new FormData();
    formData.append("excelsheet", file);

    try {
      const res = await axios.post(
        `${BASE_API_URL}/registration/bulk-registration`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res, "bulk registeration response");
      setShowModal(false);
      if (res.data.status === true) {
        toast.success("Bulk Registeration successful!");
      }
    } catch (error) {
      setShowModal(false);
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <>
      <Breadcrumbs link="/Modules" title="Modules" />
      <container fluid={true}>
        <div className="container">
          {/* <div style={{display:"flex"}}>
          <div>
          <Button color="primary" type="submit">
            {"Bulk Register Modules"}
          </Button>
          </div>

          <RegisterModule />
        </div> */}
        </div>
        <Row>
          <Col sm="12" xl="6">
            {/* <div style={{ padding: "4rem" }}>
            <div style={{ textAlign: "center", padding: "4rem" }}>
              <Button color="primary" type="submit">
                {"Bulk Register Modules"}
              </Button>
            </div>
          </div> */}
            <Card>
              <CardHeader>
                <h5>{"Bulk Register Modules"}</h5>
              </CardHeader>
              <CardBody style={{ textAlign: "center", padding: "2rem" }}>
                <Form>
                  <div className="dz-message needsclick">
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      onSubmit={handleSubmit}
                      accept="image/*"
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>{"Register Module"}</h5>
              </CardHeader>
              <div style={{ textAlign: "center", padding: "4.6rem" }}>
                <Button color="primary" type="submit" onClick={Modaltoggle}>
                  {"Register Module"}
                </Button>
                <RegisterModule
                  showModal={showModal}
                  Modaltoggle={Modaltoggle}
                  setShowModal={setShowModal}
                  setRefetch={setRefetch}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <div>
          <h5>Modules List</h5>
          <ModuleTable refetch={refetch} setRefetch={setRefetch} />
        </div>
      </container>
    </>
  );
}

export default Module;
