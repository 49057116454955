
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, CardBody, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { BASE_API_URL } from "../../../../api";


export const ApproveClaim = ({approveModal, showApproveModal, setShowApproveModal, claimID, handleApproveClaim}) => {
    const [comments, setComments] = useState("");

    





    return(
        <Modal isOpen={showApproveModal} toggle={approveModal} size="xl">
        <ModalHeader>{`Approve Claim`} </ModalHeader>
        <ModalBody>
          <CardBody>
            <Form className="needs-validation" noValidate="">
              <Col md="3 mb-3">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label style={{ width: "57%", marginRight: "1rem" }}>
                    {"Comments"}
                  </Label>
  
                  <textarea
                    style={{width: "30rem"}}
                    className="form-control"
                    name="comments"
                    type="text"
                    placeholder="Comment"
                    onChange={(e) => setComments(e.target.value)}
                    // value={claimID}
                  />
                </div>
              </Col>
            </Form>
          </CardBody>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={approveModal}>
            Close
          </Button>
          <Button color="primary" onClick={()=>handleApproveClaim(comments)}>
            Submit
          </Button>

        </ModalFooter>
      </Modal>
    )
}