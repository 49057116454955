import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ApexCharts from 'react-apexcharts';
import Knob from 'knob';
import ChartistChart from 'react-chartist';
import {
  smallchart1data,
  smallchart1option,
  smallchart2data,
  smallchart2option,
  smallchart3data,
  smallchart3option,
  smallchart4data,
  smallchart4option,
} from './chartsData/chartist-charts-data';
import {Currentlysale, Marketvalue} from './chartsData/apex-charts-data';
import {
  Dashboard,
  Summary,
  Notification,
  New,
  Tomorrow,
  Yesterday,
  Daily,
  Weekly,
  Monthly,
  Yearly,
  Hot,
  Today,
} from '../../constant';
import {
  getAdminDasboardDetails,
  getAlerts,
  getClaimMetrics,
  getClientDasboardDetails,
  getDashboardMetrics,
  getDashboardStringData,
} from '../../api';
import moment from 'moment';
import {
  primary,
  secondary,
} from '../application/Solar-app/string/StringChartData';
import {useSelector} from 'react-redux';
import jwtDecode from 'jwt-decode';
import Chart from 'react-apexcharts';

const ClientDashboard = () => {
  const [daytimes, setDayTimes] = useState();
  const today = new Date();
  const curHr = today.getHours();
  const curMi = today.getMinutes();
  const [meridiem, setMeridiem] = useState('AM');
  const {user} = useSelector((state) => state?.auth);

  const adaniUser = user && jwtDecode(user);

  // const [user, setUser] = useState(localStorage.getItem("user") || null);
  const [notifications, setNotifications] = useState([]);
  const [startingDate, setStartingDate] = useState(new Date('2015-01-01'));
  const [endDate, setEndDate] = useState(new Date());
  const [claimData, setClaimData] = useState([]);
  const [metrics, setMetrics] = useState({
    modulesCreated: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: 'Watts',
    },
    modulesSold: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: 'Watts',
    },
    modulesRegistered: {efficiency: 0, count: 0, efficiencyUnit: 'Watts'},
    modulesInstalled: {
      efficiency: 0,
      count: 0,
      efficiencyUnit: 'Watts',
    },
    warrantyClaims: 0,
    maxPower: {
      converted_power: 0,
      timestamp: '',
      string: {
        name: '',
      },
    },
    minPower: {
      converted_power: 0,
      timestamp: '',
      string: {
        name: '',
      },
    },
  });
  const [bestStrings, setBestStrings] = useState({});
  const [worstStrings, setWorstStrings] = useState({});

  const loadNotificationData = async () => {
    const alerts = await getAlerts(3, 100);
    console.log(alerts);
    setNotifications(alerts);
  };

  const loadMetricsData = async () => {
    const metricsData = await getDashboardMetrics({
      startDate: startingDate?.toISOString(),
      endDate: endDate?.toISOString(),
    });
    console.log(metricsData);
    setMetrics(metricsData);
  };

  const loadClaimData = async () => {
    const claims = await getClaimMetrics({
      startDate: startingDate?.toISOString(),
      endDate: endDate?.toISOString(),
      // user_id: adaniUser?.id,
    });
    setClaimData(claims);
  };

  const loadBestStringData = async () => {
    const bestStringData = await getDashboardStringData('best', {
      stringCount: 1,
    });
    console.log(bestStringData);
    setBestStrings(bestStringData);
  };

  const loadWorstStringData = async () => {
    const worstStringData = await getDashboardStringData('worst', {
      stringCount: 1,
    });
    console.log(worstStringData);
    setWorstStrings(worstStringData);
  };

  useEffect(() => {
    loadNotificationData();
    loadMetricsData();
    loadBestStringData();
    loadWorstStringData();
    loadClaimData();
  }, []);

  const startDate = new Date();
  const handleChange = (date) => {
    new Date();
  };

  // useEffect(() => {
  //   if (curHr < 12) {
  //     setDayTimes("Good Morning");
  //   } else if (curHr < 18) {
  //     setDayTimes("Good Afternoon");
  //   } else {
  //     setDayTimes("Good Evening");
  //   }

  //   if (curHr >= 12) {
  //     setMeridiem("PM");
  //   } else {
  //     setMeridiem("AM");
  //   }

  //   var ordervalue1 = Knob({
  //     value: metrics?.warrantyClaims || 0,
  //     angleOffset: 0,
  //     thickness: 0.3,
  //     width: 65,
  //     fgColor: "#7366ff",
  //     readOnly: false,
  //     dynamicDraw: true,
  //     tickColorizeValues: true,
  //     bgColor: "#eef5fb",
  //     lineCap: "round",
  //     displayPrevious: false,
  //   });
  //   const knobElement = document.getElementById("ordervalue1");
  //   knobElement.innerHTML = "";
  //   knobElement.appendChild(ordervalue1);
  // }, [curHr]);

  const notificationItems = notifications.map((notification) => {
    return (
      <div className="media" key={notification?._id}>
        <Link
          to={`/${notification?.type?.toLowerCase()}/${
            notification?.reference_id
          }`}
        >
          <div className="media-body">
            <p>
              {moment(notification?.createdAt).format('DD/MM/YYYY')}{' '}
              <span>{moment(notification?.createdAt).format('HH:mm')}</span>
            </p>
            <h6>
              {notification?.message?.toUpperCase()}
              <span className="dot-notification"></span>
            </h6>
            <span>{`Type: ${notification?.type} | ID: ${notification?.reference_id}`}</span>
          </div>
        </Link>
      </div>
    );
  });

  console.log(adaniUser, 'metrics');
  console.log(claimData, 'claims');

  return (
    <Fragment>
      <Breadcrumb link="/dashboard" parent="Dashboard" title="Dashboard" />

      <Container fluid={true}>
        <Row>
          <Col md="2 mb-3">
            <Label>Filter By Start Date</Label>
            <DatePicker
              className="form-control digits"
              placeholderText="Filter by start date"
              selected={startingDate}
              onChange={(date) => setStartingDate(date)}
            />
          </Col>
          <Col md="2 mb-3">
            <Label>Filter By End Date</Label>
            <DatePicker
              className="form-control digits"
              placeholderText="Filter by end date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Col>
        </Row>
        <Row className="second-chart-list third-news-update">
          <Col xl="9 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesRegistered?.count}</h4>
                          <span>{'Modules Registered'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesRegistered?.efficiency / 1000
                            ).toFixed(3)}{' '}
                            KW
                          </h4>
                          <span>{'Module registered Capacity'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.modulesInstalled?.count}</h4>
                          <span>{'Modules Installed'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {(
                              metrics?.modulesInstalled?.efficiency / 1000
                            ).toFixed(3)}{' '}
                            KW
                          </h4>
                          <span>{'Modules installed Capacity'}</span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {adaniUser?.roles?.includes('ADMIN') && (
                    <>
                      {' '}
                      <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                        <div className="media align-items-center">
                          <div className="hospital-small-chart">
                            <div className="small-bar">
                              <ChartistChart
                                className="small-chart1 flot-chart-container"
                                data={smallchart2data}
                                options={smallchart2option}
                                type={'Bar'}
                                listener={{
                                  draw: function (data) {
                                    if (data.type === 'bar') {
                                      data.element.attr({
                                        style: 'stroke-width: 3px',
                                      });
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="right-chart-content">
                              <h4>{metrics?.modulesCreated?.count}</h4>
                              <span>{'Created Modules'}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                        <div className="media align-items-center">
                          <div className="hospital-small-chart">
                            <div className="small-bar">
                              <ChartistChart
                                className="small-chart1 flot-chart-container"
                                data={smallchart2data}
                                options={smallchart2option}
                                type={'Bar'}
                                listener={{
                                  draw: function (data) {
                                    if (data.type === 'bar') {
                                      data.element.attr({
                                        style: 'stroke-width: 3px',
                                      });
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="right-chart-content">
                              <h4>{metrics?.modulesSold?.count}</h4>
                              <span>{'Sold Modules'}</span>
                            </div>
                          </div>
                        </div>
                      </Col>{' '}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8" lg="6" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="3" lg="12" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" lg="12" className="p-0 left_side_earning">
                        <h5>{Dashboard}</h5>
                        <p className="font-roboto">
                          {moment(endDate).format('lll')}
                        </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {metrics?.maxPower?.converted_power?.toFixed(1)} Watts{' '}
                        </h5>
                        <p className="font-roboto">{'Best String Power'}</p>
                        <h6>
                          <Link
                            to={`/string/${metrics?.maxPower?.string?._id}`}
                          >
                            {' '}
                            {metrics?.maxPower?.string?.name}
                          </Link>
                        </h6>{' '}
                        <h6 style={{fontSize: '12px'}}>
                          {moment(metrics?.maxPower?.timestamp).format('lll')}
                        </h6>
                      </Col>
                      <hr
                        style={{
                          borderTop: '1px solid #bebebe',
                          width: '80%',
                          height: '10px',
                        }}
                      />
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {' '}
                          {metrics?.minPower?.converted_power?.toFixed(1)} Watts
                        </h5>
                        <p className="font-roboto">{'Worst String Power'}</p>
                        <h6>
                          <Link
                            to={`/string/${metrics?.minPower?.string?._id}`}
                          >
                            {' '}
                            {metrics?.minPower?.string?.name}
                          </Link>
                        </h6>{' '}
                        <h6 style={{fontSize: '12px'}}>
                          {moment(metrics?.minPower?.timestamp).format('lll')}
                        </h6>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>
                          {metrics?.maxPower?.timestamp
                            ? moment(metrics?.maxPower?.timestamp).format(
                                'MMM yyyy'
                              )
                            : ''}
                        </h5>
                        <p className="font-roboto">{'Max Power Month'}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="9" lg="12" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            {/* <ul className="d-flex list-unstyled">
                              <li>{Daily}</li>
                              <li className="active">{Weekly}</li>
                              <li>{Monthly}</li>
                              <li>{Yearly}</li>
                            </ul> */}
                          </div>
                        </Col>
                        <Col
                          xl="4"
                          md="4"
                          sm="4"
                          className="col-12 p-0 justify-content-end"
                        ></Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts
                                id="chart-currently"
                                options={{
                                  colors: [primary, secondary],
                                  fill: {
                                    type: 'gradient',
                                    gradient: {
                                      shadeIntensity: 1,
                                      opacityFrom: 0.7,
                                      opacityTo: 0.5,
                                      stops: [0, 80, 100],
                                    },
                                  },
                                  stroke: {
                                    width: 3,
                                  },
                                  grid: {
                                    show: false,
                                  },
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  xaxis: {
                                    type: 'datetime',
                                    tickAmount: 1,
                                    min: new Date("'2019-01-01").getTime(),
                                    title: {
                                      text: 'Time',
                                    },
                                  },
                                  yaxis: {
                                    title: {
                                      text: 'Power (W)',
                                    },
                                    decimalsInFloat: 1,
                                  },
                                }}
                                series={[
                                  ...Object.values(bestStrings).map(
                                    (string, i) => {
                                      return {
                                        name: `Best String: ${
                                          Object.keys(bestStrings)[i]
                                        }`,
                                        data: string.map((item) => ({
                                          y: item?.converted_power,
                                          x: new Date(
                                            item?.timestamp
                                          ).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                                        })),
                                      };
                                    }
                                  ),
                                  ...Object.values(worstStrings).map(
                                    (string, i) => {
                                      return {
                                        name: `Worst String: ${
                                          Object.keys(worstStrings)[i]
                                        }`,
                                        data: string.map((item) => ({
                                          y: item?.converted_power,
                                          x: new Date(
                                            item?.timestamp
                                          ).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                                        })),
                                      };
                                    }
                                  ),
                                ]}
                                type="area"
                                height={450}
                              />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" lg="6" className="notification box-col-6">
            <Card
              style={{height: '550px', overflowY: 'scroll'}}
              className="custom-scrollbar"
            >
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Notifications</h5>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{Today}</option>
                      <option>{Tomorrow}</option>
                      <option>{Yesterday}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">{notificationItems}</CardBody>
            </Card>
          </Col>

          {/* <Col xl="3 xl-50" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <span>{"Warranty Claims Filed"}</span>
                  </div>
                  <div className="knob-block text-center">
                    <div className="knob1" id="ordervalue1"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col xl="6" lg="6" className="notification box-col-6">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Claims Analytics</h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [primary, secondary],
                      labels: claimData.map((data) => data.status),
                    }}
                    series={claimData.map((data) => data.count)}
                    type="donut"
                    height={225}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{metrics?.warrantyClaims}</h4>
                          <span>{'Warranty Claims Filed'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {claimData?.filter(
                              (claim) => claim.status === 'APPROVED'
                            )?.[0]?.count || 0}
                          </h4>
                          <span>{'Warranty Claims Approved'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 chart-main">
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {' '}
                            {claimData?.filter(
                              (claim) => claim.status === 'PENDING'
                            )?.[0]?.count || 0}
                          </h4>
                          <span>{'Warranty Claims on hold'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              draw: function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px',
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>
                            {claimData?.filter(
                              (claim) => claim.status === 'REJECTED'
                            )?.[0]?.count || 0}
                          </h4>
                          <span>{'Warranty Claims Rejected'} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ClientDashboard;
