import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import {
  MyProfile,
  Bio,
  MarkJecno,
  Designer,
  Password,
  Website,
  Save,
  EditProfile,
  Company,
  Username,
  UsersCountryMenu,
  AboutMe,
  UpdateProfile,
  UsersTableTitle,
  FirstName,
  LastName,
  Address,
  EmailAddress,
  PostalCode,
  Country,
  UsersTableHeader,
  City,
  Edit,
  Update,
  Delete,
} from "../../../constant";
import { classes } from "../../../data/layouts";
import { useForm } from "react-hook-form";
import {
  BASE_API_URL,
  getModuleData,
  getModulesList,
  registerModules,
} from "../../../api";
import { toast } from "react-toastify";
const RegisterModule = ({
  showModal,
  setShowModal,
  Modaltoggle,
  setRefetch,
}) => {
  let token = localStorage.getItem("token");

  const obj = {
    serial_number: "",
    
  }
  
  const [moduleObj, setModuleObj] = useState(obj);

  const [moduleData, setModuleData] = useState([]);
  const [moduleValue, setModuleValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const { data } = await getModulesList();
    setModuleData(data.data);
  };

  const loadData = async () => {
    getData();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    let finalObj = moduleObj;
    try {
      const { data } = await axios.post(
        `${BASE_API_URL}/registration`,
        finalObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data, "res for registeration");
      if (data.status === true) {
        setShowModal(false);
        setRefetch(true);
        setIsLoading(false);
        toast.success("Module registered Successfully!");
      }
    } catch (error) {
      setShowModal(false);
      setIsLoading(false);
      toast.error("Something went Wrong");
    }
  };




  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={Modaltoggle} size="lg">
        <ModalHeader>{"Register Module"}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
            <Col>
              <Label htmlFor="validationCustom01">{"Module Serial Number"}</Label>
                  <input
                  required
                    className="form-control"
                    name="serial_number"
                    type="text"
                    placeholder="Module Serial Number"
                    onChange={(e) => setModuleObj({...moduleObj, serial_number: e.target.value})}
                  /></Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={Modaltoggle}>
            {"Close"}
          </Button>
          <Button
            style={isLoading ? { cursor: "not-allowed" } : {}}
            className={isLoading && `disabled`}
            color="primary"
            onClick={handleSubmit}
          >
            {isLoading ? "Registering..." : "Register"}
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Container fluid={true}> */}

      {/* <div className="edit-profile">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row>
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom02">
                          Module Number
                        </Label>
                        <input
                          className="form-control"
                          name="moduleNumber"
                          type="number"
                          onChange={(e) => setModuleValue(e.target.value)}
                          value={moduleValue}
                          placeholder="Enter your module number"
                          // {...register("moduleNumber", { required: true })}
                        />
                        <span>
                          {errors.moduleNumber && "Module number is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                      {/* <Col md="4 mb-3">
                        <Label htmlFor="validationCustom01">Email</Label>
                        <input
                          className="form-control"
                          name="email"
                          type="text"
                          onChange={(e) => setEmailValue(e.target.value)}
                          value={emailValue}
                          placeholder="Enter your email"
                          // {...register("email", { required: true })}
                        />
                        <span>{errors.email && "Email is required"}</span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col>
                      <Col md="4 mb-3">
                        <Label htmlFor="validationCustom02">
                          Mobile Number
                        </Label>
                        <input
                          className="form-control"
                          name="phoneNumber"
                          type="number"
                          onChange={(e) => setNumberValue(e.target.value)}
                          value={numberValue}
                          placeholder="Last name"
                          // {...register("phoneNumber", { required: true })}
                        />
                        <span>
                          {errors.phoneNumber && "Mobile number is required"}
                        </span>
                        <div className="valid-feedback">{"Looks good!"}</div>
                      </Col> */}
      {/* <Col md="4 mb-3">
                        <Label htmlFor="validationCustomUsername">
                          {Username}
                        </Label>
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <Input
                            className="form-control"
                            name="userName"
                            type="text"
                            placeholder="Username"
                            {...register("userName", { required: true })}
                          />
                          <span>
                            {errors.lastName && "User name is required"}
                          </span>
                        </InputGroup>
                      </Col> */}
      {/* </Row> */}
      {/* <Row>
                      <Col md="6 mb-3">
                        <Label htmlFor="validationCustom03">{City}</Label>
                        <input
                          className="form-control"
                          name="city"
                          type="text"
                          placeholder="City"
                          {...register("city", { required: true })}
                        />
                        <span>
                          {errors.city && "Please provide a valid city"}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid city."}
                        </div>
                      </Col>
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom04">State</Label>
                        <input
                          className="form-control"
                          id="validationCustom04"
                          name="state"
                          type="text"
                          placeholder="State"
                          {...register("state", { required: true })}
                        />
                        <span>
                          {errors.state && "Please provide a valid state."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid state."}
                        </div>
                      </Col>
                      <Col md="3 mb-3">
                        <Label htmlFor="validationCustom05">Zip</Label>
                        <input
                          className="form-control"
                          id="validationCustom05"
                          name="zip"
                          type="text"
                          placeholder="Zip"
                          {...register("zip", { required: true })}
                        />
                        <span>
                          {errors.zip && "Please provide a valid zip."}
                        </span>
                        <div className="invalid-feedback">
                          {"Please provide a valid zip."}
                        </div>
                      </Col>
                    </Row> */}
      {/* <FormGroup>
                      <div className="form-check">
                        <div className="checkbox p-0">
                          <Input
                            className="form-check-input"
                            id="invalidCheck"
                            type="checkbox"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="invalidCheck"
                          >
                            {"Agree to terms and conditions"}
                          </Label>
                        </div>
                        <div className="invalid-feedback">
                          {"You must agree before submitting."}
                        </div>
                      </div>
                    </FormGroup> 

                    <Button color="primary" type="submit">
                      {"submit"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>*/}
    </Fragment>
  );
};

export default RegisterModule;
