import React from 'react';
import {useSelector} from 'react-redux';
import jwtDecode from 'jwt-decode';
import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';

const Default = () => {
  const {user} = useSelector((state) => state?.auth);
  const adaniUser = user && jwtDecode(user);

  const isAdmin = (user) => {
    return user?.roles?.includes('ADMIN');
  };

  return isAdmin(adaniUser) ? <AdminDashboard /> : <ClientDashboard />;
};

export default Default;
