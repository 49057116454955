import React, { Fragment } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import Chart from "react-apexcharts";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { primary, secondary } from "../string/StringChartData";

import dummyPlantPerfomance from "../../../../api/mock-data/plant-data.json";
import dummyBlockPerfomance from "../../../../api/mock-data/block-data.json";

const Plantchart = () => {
  const [plantPerformanceData, setPlantPerformanceData] = useState([]);
  const [blockPerformanceData, setBlockPerformanceData] = useState({});

  const plantId = useSelector((state) => state?.Plant?.plantId);

  const loadPlantPerformanceData = async (plantId) => {
    const data = dummyPlantPerfomance?.[plantId] || [];
    setPlantPerformanceData(data);
  };

  const loadBlockPerformanceData = async (plantId) => {
    let data = dummyBlockPerfomance?.filter(
      (blockData) => blockData.plant_id === plantId
    );
    data = data.reduce((acc, current) => {
      acc[[current?.name]] = acc[[current?.name]]
        ? acc[[current?.name]].concat(current)
        : [];
      return acc;
    }, {});
    setBlockPerformanceData(data);
  };

  useEffect(() => {
    loadPlantPerformanceData(plantId);
    loadBlockPerformanceData(plantId);
  }, [plantId]);
  return (
    <Fragment>
      {/* <Breadcrumb title="Plant" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>Plant Performance </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      yaxis: {
                        title: {
                          text: "Power (MWh)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        title: {
                          text: "Time",
                        },
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          plantPerformanceData?.[0]?.date
                        ).getTime(),
                      },
                    }}
                    series={[
                      {
                        name: "Plant Performance",
                        data: plantPerformanceData.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>Block Performance </h5>
              </CardHeader>
              <CardBody>
                <Chart
                  options={{
                    colors: [primary, secondary],
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.5,
                        stops: [0, 80, 100],
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    grid: {
                      show: false,
                    },
                    yaxis: {
                      title: {
                        text: "Specific Yield (KWh)",
                      },
                      decimalsInFloat: 3,
                    },
                    xaxis: {
                      title: {
                        text: "Time",
                      },
                      type: "datetime",
                      tickAmount: 1,
                      min: new Date(plantPerformanceData?.[0]?.date).getTime(),
                    },
                  }}
                  series={[
                    ...Object.values(blockPerformanceData).map((block, i) => {
                      return {
                        name: `${Object.keys(blockPerformanceData)[i]}`,
                        data: block.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      };
                    }),
                  ]}
                  height="350"
                  type="area"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Plantchart;
