import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import Chart from "react-apexcharts";
import { areaSpaline, apexAreaChart } from "./InverterChartData";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useSelector } from "react-redux";

import dummyInverterPerfomance from "../../../../api/mock-data/inverter-data.json";
import { primary, secondary } from "../string/StringChartData";
import { getStringPerformanceData } from "../../../../api";
import moment from "moment";
import axios from "axios";

const Inverterchart = () => {
  const [inverterPerformanceData, setInverterPerformanceData] = useState([]);
  const [stringPerformanceData, setStringPerformanceData] = useState({});

  const inverterId = useSelector((state) => state?.Inverter?.inverterId);

  const loadInverterPerformanceData = async (inverterId) => {
    let data = dummyInverterPerfomance?.filter(
      (inverterData) => inverterData.inverter_id === inverterId
    );

    setInverterPerformanceData(data);
  };

  //change in api later
  const temporaryStringPerformanceFunction = async (filters) => {
    try {
      const token = localStorage.getItem("token");
      const URL = "https://projects.emertech.io/poc/adani/module-trace/api";
      const url = `${URL}/string/performance`;
      const performanceData = await axios.get(url, {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = performanceData?.data?.data || [];

      data.sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)));
      return data;
    } catch (error) {}
  };

  const loadStringPerformanceData = async (inverterId) => {
    let data = await temporaryStringPerformanceFunction();
    data = data?.filter(
      (stringPerformance) => stringPerformance.string.inverter_id === inverterId
    );

    data = data.reduce((acc, current) => {
      acc[[current?.string.name]] = acc[[current?.string.name]]
        ? acc[[current?.string.name]].concat(current)
        : [];
      return acc;
    }, {});
    setStringPerformanceData(data);
  };

  useEffect(() => {
    loadInverterPerformanceData(inverterId);
    loadStringPerformanceData(inverterId);
  }, [inverterId]);

  return (
    <Fragment>
      <Breadcrumb title="Inverter" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>Inverter Performance </h5>
              </CardHeader>
              <CardBody>
                <div id="basic-apex">
                  <Chart
                    options={{
                      colors: [secondary],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shadeIntensity: 1,
                          opacityFrom: 0.7,
                          opacityTo: 0.5,
                          stops: [0, 80, 100],
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      grid: {
                        show: false,
                      },
                      yaxis: {
                        title: {
                          text: "Power (KWh)",
                        },
                        decimalsInFloat: 1,
                      },
                      xaxis: {
                        title: {
                          text: "Time",
                        },
                        type: "datetime",
                        tickAmount: 1,
                        min: new Date(
                          inverterPerformanceData?.[0]?.date
                        ).getTime(),
                      },
                    }}
                    series={[
                      {
                        name: "Inverter Performance",
                        data: inverterPerformanceData.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.date).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      },
                    ]}
                    type="area"
                    height={350}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" xl="6">
            <Card>
              <CardHeader>
                <h5>String Performance </h5>
              </CardHeader>
              <CardBody>
                <Chart
                  options={{
                    colors: [primary, secondary],
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.5,
                        stops: [0, 80, 100],
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    grid: {
                      show: false,
                    },
                    yaxis: {
                      title: {
                        text: "Power (KWh)",
                      },
                      decimalsInFloat: 3,
                    },
                    xaxis: {
                      title: {
                        text: "Time",
                      },
                      type: "datetime",
                      tickAmount: 1,
                      min: new Date(
                        stringPerformanceData?.[0]?.timestamp
                      ).getTime(),
                    },
                  }}
                  series={[
                    ...Object.values(stringPerformanceData).map((string, i) => {
                      return {
                        name: `${Object.keys(stringPerformanceData)[i]}`,
                        data: string.map((item) => ({
                          y: item?.converted_power,
                          x: new Date(item?.timestamp).getTime(), //moment(item?.timestamp).format('MMM/YY'),
                        })),
                      };
                    }),
                  ]}
                  height="350"
                  type="area"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Inverterchart;
